.historic {
  width: 973px;

  &__title {
    width: 100%;
    height: 24px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  &__table{
    overflow-y: auto;
    max-height: 340px;
    display: block;
  }

  .table-admin {
    border-spacing: 0;
    min-width: 100%;
  }
}