@import '../../../../../../assets/styles/variables';

.form-add-customer {
  .outlined-label-field {
    min-width: 340px;
    margin-bottom: 24px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    button {
      font-weight: bold;
      margin-right: 16px;
    }

    &__cancel {
      background: white;
      color: #EA1D2C;
    }
  }
  &--label-error {
    align-self: flex-end;
    display: block;
    margin: 6px 0 4px;
    font-size: $font-size-small;
    color: $ifood;

    &--block {
      width: 100%;
      margin: 0;
    }
  }

  &.add-cep {
    padding: 0 24px;

    .input {
      min-width: 100%;
    }
  }

  .outlined-label-wrap {
    width: 100%;
    label {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
    }

    input {
      width: 100%;
      margin: 0;
    }

    .input--error {
      border: 1px solid $ifood;
      outline: none;
      padding: 1rem;
    }

    .ad {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 36px;
    }
  }
}