@import "../../../../assets/styles/style.scss";

.order-detail {
  &__deliveries {
    margin-top: 48px;
    border-top: 8px solid $lightgrey;

    &-seller {
      margin-top: 0;
      border-top: none;
    }
  }
  &__delivery {
    padding: 53px 0 75px;
    border-bottom: 4px solid $lightgrey;

    &:last-child {
      border-bottom: 0;
    }
    &-title {
      margin: 0 16px 0 0;
      font-weight: 500;
      font-size: $font-size-large;
    }
    &-info {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 84px;
      
      &-left {
        display: flex;
      }
    }
    &-list {
      min-width: 280px;
    }
    &-actions {
      width: 13em;
      display: flex;
      flex-direction: column;

      .button {
        margin-bottom: 14px;
      }
    }
    &-item {
      display: flex;
      justify-content: space-between;
      margin: 0 0 16px;
    }
    &-value {
      font-weight: 500;
      padding-left: 8px;
    }

    &-periods {
      color: #ea1d2c;
      font-weight: 500;
      padding-left: 8px;
    }

    &-invoice {
      flex-direction: column;

      .order-detail__delivery-title {
        margin-bottom: 16px;
      }
    }
  }
  .btn-cancel {
    text-align: center;
  }
}
