.header-product {
  margin-bottom: 24px;

  &__info {
    margin-bottom: 40px;
  }
  .header-product__description {
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
