@import "../../../../../assets/styles/style.scss";

.form-admin {
  &__input-group {
    .outlined-label-wrap {
      width: 488px;
    }
  }
  &--discount {
    padding-bottom: 80px;

    .toggle-wrap {
      margin-bottom: 0;
    }
    .togglebutton-ifood {
      margin-right: 16px;
    }
    .autocomplete-ifood {
      width: 450px;
      margin-right: 16px;
      color: $mediumgrey;
    }
    .dropdown-ifood {
      width: 200px;
      margin-right: 16px;
    }
    .label-form {
      flex: 100%;
      margin-bottom: 16px;
    }
    #discountName {
      width: 476px;
    }

    .form-admin {
      &__group {
        align-items: center;
        margin-bottom: 24px;

        &--author {
          align-items: flex-end;
        }
        &--title-conditions {
          align-items: baseline;

          .section-title {
            margin-right: 270px;
          }
        }
        &.optional {
          flex-direction: column;
          align-items: flex-start;

          .outlined-label-wrap {
            margin-bottom: 8px;
          }
        }
        .outlined-label-wrap.outlined-date {
          margin-right: 16px;
        }
      }
      &__area {
        margin-top: 80px;

        .section-title {
          margin-bottom: 24px;
        }
        &--subsidy {
          .togglebutton-ifood {
            flex: 100%;
            margin-bottom: 16px;
          }
          .section-title {
            margin-bottom: 8px;
          }
          .subtitle-subsidy {
            margin: 0 0 24px;
          }
        }
      }
      .outlined-date {
        margin-right: 16px;
      }
    }

    .react-datepicker {
      width: 330px;

      &__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        right: 100px;
      }
      &__time-container {
        .react-datepicker__time {
          .react-datepicker__time-box {
            ul.react-datepicker__time-list {
              li.react-datepicker__time-list-item {
                line-height: 23px;

                &--selected {
                  background-color: $ifood;
                }
              }
            }
          }
        }
      }
    }

    &__btn-group {
      display: flex;
      gap: 16px;
      justify-content: flex-end;
      margin-top: 55px;
    }
  }
}
.label-success {
  &--discount {
    position: fixed;
    top: 16px;
    right: 16px;
  }
}

