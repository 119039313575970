.form-admin {
  &--voucher {
    .form-admin {
      &__group {
        &--comments {
          margin-bottom: 24px;

          .outlined-label-wrap {
            width: 700px;
            height: auto;
          }
        }
      }
    }
    .autocomplete-ifood {
      width: 480px;
    }
  }
}