// Colors
$offwhite: #F7F7F7;
$white: #FFFFFF;
$lightgrey: #F2F2F2;
$regulargrey: #DCDCDC;
$mediumgrey: #A6A6A6;
$heavygrey: #717171;
$black: #3E3E3E;
$ifood: #EA1D2C;
$red-light: #EF5753;
$red-dark: #BC002D;
$avocado: #50A773;
$greenlight :#DEF0E5;
$blueberry: #2E6788;
$mustard: #E7A74E;
$orange: #FF7752;
$bubblegum: #FCEBEA;

//Spacings
$spacing: 16px;

// Typography
$font-family: 'SulSans', sans-serif;
$font-size-small: 12px;
$font-size-medium: 14px;
$font-size-large: 16px;
$font-size-Xmedium: 18px;
$font-size-title: 20px;
$font-size-Xlarge: 24px;
