@import '../../../../assets/styles/variables.scss';

.modal-warning {

  .modal {
    &__body {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__description {
    font-size: $font-size-large;
    font-weight: 500;
    line-height: 24px;
    margin-top: 0px;
    margin-bottom: 20px;
  }
}