.sale-condition {
  &__title {
    padding-bottom: 16px;
  }

  &__fields {
    width: 320px;
    padding-bottom: 24px;

    .outlined-label-field {
      height: 48px;
    }
  }

  .button-suggestion {
    padding-top: 24px;

    .button,
    .btn-secondary {
      display: grid !important;
      align-items: center;
      width: 168px;
      height: 48px;
      font-size: 18px;
      margin-left: 16px;
      text-align: center;
      border-radius: 5px;
    }
  }
  .btn--red {
    margin-left: 16px;
    text-decoration: none;

    &.active,
    &:hover {
      &:not([disabled]) {
        cursor: pointer;
      }
    }

    &[disabled] {
      cursor: default;
      background: #dcdcdc;
      color: #a6a6a6;
      border: none;
    }
  }
}
