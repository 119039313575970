.modal-freight-range {
  &__description {
    text-align: center;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    min-width: 420px;
    padding: 16px 0;
  }
}