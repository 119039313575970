.modal-product {
  .modal-wrapper > .modal {
    max-width: 590px;
    line-height: 24px;
  }
}
.search-product {
  .header-customer-group {
    &--src {
      position: relative;
      margin-bottom: 0;

      .header-customer-group__new {
        display: flex;
      }
    }

    &__actions-link {
      &--export {
        margin-right: 16px;
        width: auto;
      }
    }

    &__info {
      margin-bottom: 40px;
    }

    &__new {
      margin-bottom: 24px;
      align-items: baseline;

      .new-product {
        margin-bottom: 16px;
        margin-right: 16px;
      }
    }

    .options-link {
      width: 200px;
    }
    &__actions-more {
      top: 19%;
    }

    .form-row {
      .dropdown-label-wrap:first-child {
        margin-left: 0px;
      }
    }
  }

  .table-admin {
    &--sample {
      margin-bottom: 24px;

      thead {
        .table-admin {
          &__title {
            &:first-child {
              min-width: 70px;
            }

            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5) {
              min-width: 150px;
            }
          }
        }
      }
    }
  }
}
