.hasHistory {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 70%;
  margin-top: 24px;

  .button {
    margin-left: 15px;
  }
}