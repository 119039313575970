.modal-select-sellers-export {
    width: 500px;

    &__subtitle {
        padding: 8px 0;
    }

    .flex-grid.flex-grid--align-stretch.flex-grid--wrap.modal-select-sellers-export__search {
        padding: 16px 0;
        align-items: center !important;
    }

    &__button-add, &__button-export {
        float: right;
    }

    &__button-export {
        margin-top: 24px;
    }
}
.MuiAutocomplete-popper {
    z-index: 999999 !important;
}