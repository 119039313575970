@import '../../../../assets/styles/variables';

.modal-content-quantity {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  height: auto;
  width: 443px;

  &__title{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: $black;
    align-self: flex-start;
    padding-left: 17px;
    margin-top: -25px;
  }

  &__description {
    font-size: 16px;
    line-height: 20px;
    padding-left: 16px;
    margin: 12px 0;
    color: $heavygrey;
  }

  &__count {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    .count-name {
      font-size: 12px;
      line-height: 16px;
      padding-left: 18px;
      padding-right: 10px;
      min-width: 339px;
      color: $black;
    }

    .count-increment-decrement {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      width: 165px;
    }

    .count-number {
      font-size: 12px;
      line-height: 24px;
      font-weight: 700;
      color: $black;
      border: solid 1px #ddd;
      border-radius: 6px;
      padding: 5px 3px;
      margin: 0 5px;
    }

    .counter-button{ 
      background-color: $white;  
      background-repeat: no-repeat;  
      background-position: center;  
      border-radius: 4px;  
      height: 12px;  
      width: 12px;  
      text-decoration: none;  
      border-style: none;  
      outline: none;  
      border: solid 1px #ddd;  
      padding: 16px 10px;  
      &:hover {  
        cursor: pointer;  
      }    
    }  
    .counter_minus{  
      &--active{  
        background-image: url("../../../../assets/icons/minus-red.svg");  
      }  
      &--inactive{  
        background-image: url("../../../../assets/icons/minus-grey.svg");  
      }  
    }  
    .counter_plus{  
      &--active{  
        background-image: url("../../../../assets/icons/plus-red.svg");  
      }  
      &--inactive{  
        background-image: url("../../../../assets/icons/plus-grey.svg");  
      }  
    }  
  }
  &_confirm{
    min-width: 96px;
    justify-content: center;
  }
   // override default css
  .button {
    font-size: 16px;
    padding: 0 10px;
  }

}
