.form-register {
  margin-bottom: 16px;
  margin-left: 33px;
  margin-right: 33px;
  display: flex;
  flex-direction: column;

  &__special-price {
    padding: 0px 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .input {
    width: 239px;
  }
}

.special-price {
  &__submit {
    display: flex;
    width: 100%;
    justify-content: center;

    .button {
      width: 120px;
      justify-content: center;
    }
  }
}

.progressive-price {
  margin-bottom: 0px;

  .price-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .price-line {
    display: flex;
    margin-top: 24px;
    width: 520px;
    justify-content: space-between;
  }
  .clicable-text {
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: right;
    color: #ea1d2c;
    margin-right: 24px;
  }
  .second-dropdown {
    margin-top: 24px;
  }

  .outlined-label {
    font-size: 12px;
  }
}

.react-datepicker {
  &__input-container {
    .form-brand {
      &__input {
        width: 100%;
      }
    }
  }
}

.modal-wrapper {
  > .modal {
    > .modal {
      &__title {
        padding-left: 16px;
        text-align: left;
      }
    }
  }
}

.outlined-label-wrap {
  width: 244px;
}

.condition-container {
  .dropdown-label-wrap {
    width: 239px;
    margin-left: 0;
  }
}

.date-line {
  display: flex;
  padding: 0;
  .checkbox-ifood {
    padding: 0;
  }
  h5 {
    margin-left: 9px;
  }
}

.input-line {
  margin-top: 16px;
  width: 504px;
  display: flex;
  justify-content: space-between;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.image-container {
  width: 45px;
  height: 45px;
}

.price-title {
  margin-top: 0px;
}
