@import '../../../assets/styles/variables';

.message {
  color: $heavygrey;
  width: 452px;
  
  &__called {
    display: block;
    padding: 20px;
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    background: $lightgrey;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 26px;
  }

  .link {
    display: block;
    text-align: center;
  }
}