@import '../../../../assets/styles/variables';

.product-variation-tabs {
	margin-top: 56px;
	padding-top: 56px;
	border-top: 4px solid $lightgrey;
}

.MuiTypography-root {
	.header-customer-group {
		margin-bottom: 0px;
	}
}

.table-admin {
  &--offer {
    thead {
      .table-admin {
        &__title:first-child {
          min-width: 300px;
        }
      }
    }
  }
}