@import '../../assets/styles/variables';

.select-links {
  max-width: 176px;
  position: relative;

  &__button {
    float: left;
    justify-content: space-between;
    align-items: baseline;
    padding: 8px 16px 6px;
    line-height: 24px;
    color: $mediumgrey;
    border: 1px solid $regulargrey;
    border-radius: 4px;
    cursor: pointer;
  }

  &__icon {
    margin-left: 9px;
    transition: all 0.4s;

    > * {
      fill: $ifood
    }

    &.open {
      transform: rotate(180deg);
    }
  }

  &__list {
    position: absolute;
    top: 40px;
    display: flex;
    flex-flow: column;
    width: 100%;
    padding: 8px 0;
    border: 1px solid $regulargrey;
    border-radius: 3px;
    box-shadow: inset 0px 0px 6px -1px rgba(0, 0, 0, 0.11);

    .item {
      padding: 12px 20px;
      border-bottom: 1px solid $lightgrey;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  &.disabled {
    .select-links {
      &__button {
        cursor: not-allowed;
      }

      &__icon {
        > * {
          fill: $regulargrey
        }
      }
    }
  }
}