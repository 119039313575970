@import "../../../../assets/styles/style.scss";

.form-brand {
  &--suggest {
    width: 100%;

    .form-row {
      .outlined-date {
        margin-right: 16px;
      }
    }

    .dropdown-label-wrap,
    .outlined-label-wrap {
      width: 248px;
      margin-right: 16px;
    }

    .MuiAutocomplete-endAdornment {
      .MuiIconButton-root {
        transition: all 0.3s;
      }

      .dropdown-ifood__arrow {
        transform: translateY(0);
        transition: all 0.3s;
      }
    }

    .react-datepicker {
      width: auto;

      &__input-container {
        .form-brand__input {
          width: 248px;
        }
      }
    }
  }

  .MuiInputLabel-outlined {
    transform: translate(12px, 20px) scale(1);
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 6.5px 4px 6.5px 4px;
  }

  .header-customer-group__actions-link {
    width: 200px;
  }
}