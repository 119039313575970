.form-carrier {
  .form-carrier {
    &__actions {
      margin-top: 24px;
    }
  }

  .table-admin {
    min-width: auto;
    width: 100%;
  }

  .address{
    &__address-field {
      .outlined-label-wrap{
        width: 96%;
      }
    }

    &__number-field {
      .outlined-label-wrap{
        width: 70%;
      }
    }
  }

  .error-class {
    .Dropdown-control{
      color: #EA1D2C;
    }
  }
}