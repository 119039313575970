@import "../../../assets/styles/style.scss";

.modal--button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  & button + button {
    margin-left: 16px;
  }
  
}

.modal--link a {
  margin-top: 8px;
}

.logo-upload {
  &__button {
    a {
      margin-top: 40px !important;
    }
  }

  &__iconPrev {
    background: url('../../../assets/icons/icon-goback.svg') no-repeat;
    width: 10px;
    height: 40px;
    outline: none;
    border: none;
    cursor: pointer;
  }

  &__iconNext {
    background: url('../../../assets/icons/icon-goback.svg') no-repeat;
    width: 10px;
    height: 40px;
    outline: none;
    border: none;
    transform: rotate(180deg);
    cursor: pointer;
  }

  &__preview {
    margin-top: 16px;
    margin-bottom: 16px;
    position: relative;
    width: 200px;
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px solid #ea1d2c;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      position: relative;
      width: 200px;
      height: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      border: 1px solid #ea1d2c;
      display: flex;
      align-items: center;
      justify-content: center;
      width: inherit;
      height: inherit;
    }
  }

  &__group {
    display: flex;
    max-width: 300px;
    margin-top: 40px;
    justify-content: space-between;
  
    p {
      font-size: 12px;
      width: 220px;
      color: #ea1d2c;
    }
  }

  &__gallery-button {
    max-width: 200px;
    display: flex;
    justify-content: space-between;
  }
}

