.form-region {
  .form-register {
    &__input {
      max-width: 200px;
      &--name {
        max-width: 400px;
      }
    }
    
    label[for="name"] {
      top: 20px;
    }

    &__button {
      margin-left: 16px;
    }

    &__description {
      margin-bottom: 24px;
    }

    &__flex {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      & input {
        margin-bottom: 0;
      }
    }

    &__toggle-button {
      margin: 2px 0 0 16px;
    }

    &__button-save {
      margin-top: 32px;
    }
  }
}
