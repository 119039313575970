@import "@assets/styles/variables";

.seller-logo {
  width: 440px;
  margin: 32px 0;


  &__subtitle {
    font-size: 20px;
    line-height: 24px;
    font-weight: 300;
    color: $black;
    margin-bottom: 24px;

    &--description {
      font-size: 16px;
      color: $heavygrey;
      margin-bottom: 20px;
      line-height: 24px;
    }
  }

  &__preview {
    display: flex;
    position: relative;
    height: 144px;
    width: 144px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border: 1px dashed #A6A6A6;
    border-radius: 6px;
    align-items: center;
    justify-content: center;

    &--img{
      max-width: 144px;
      max-height: 144px;
      padding: 0 1px;
    }
  }

   &::after {
    content: "";
    display: block;
    width: 440px;
    height: 1px;
    background-color: $regulargrey;
    margin-top: 32px;
  }
}

.logo-input {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 144px;

  &__desc {
    font-size: 12px;
    font-weight: 500;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
    align-items: flex-end;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
  }

  &__btn {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-top: 64px;

    .button--disabled{
      display: flex;
      align-items: center;
      height: 48px;
      padding: 8px 16px;
      border-radius: 4px;
      font-size: 14px;

      &:before {
        content: '';
        display: inline-block;
        height: 10px;
        width: 10px;
        margin-right: 12px;
        align-self: center;
        background-image: url("../../../../../assets/icons/upload-icon-disabled.svg");
        background-repeat: no-repeat;
      }
    }
  }

  .btn--white {
    display: inline-block;
    height: 48px;
    padding: 16px 16px 16px 16px;
    font-size: 14px;
    cursor: pointer;

    &:before {
      content: '';
      display: inline-block;
      height: 10px;
      width: 10px;
      margin-right: 12px;
      align-self: center;
      background-image: url("../../../../../assets/icons/upload-icon-enabled.svg");
      background-repeat: no-repeat;
    }

    &__input {
      height: 1px;
      width: 1px;
      margin: 0;
      position: absolute;
      z-index: -1;
      overflow: hidden;
      display: none;
    }
  }

  &--selected {
    display: flex;
    justify-content: space-between;

    .btn {
      align-self: flex-end;
    }

    .link{
      font-size: 14px ;
    }

    .logo-input{
      &__btn{
        padding-top: 8px;
        align-items: flex-start;
      }
    }

    .btn--white {
      &__input {
        height: 1px;
        width: 1px;
        margin: 0;
        position: absolute;
        z-index: -1;
        overflow: hidden;
        display: none;
      }
    }

    .size-alert{
      color: $ifood;
      font-size: 14px;
    }
  }

}
