.outlined-textarea {
  &--full{
    .outlined-textarea{
      width: 100%;
    }
    .outlined-label-wrap{
      margin-right: 0;
    }
  }
  .no-resize {
    resize: none;
    min-height: 105px;
  }
  .outlined-label {
    top: 20px;
  }
}