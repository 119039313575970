@import '../../../../../assets/styles/variables';

.drag-handle {
  cursor: pointer;
  width: 16px;
  margin-top: 4px;
}
.table-admin__row-drag-drop {
  .table-admin {
    &__data {
      background: white;
      box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.05);

      &:not(:first-child) {
        width: 400px;
      }
      &:first-child {
        width: 30px;
      }
      &:nth-child(2) {
        width: 100px;
      }
      &:last-child {
        border-right: none;
        width: 100px;
      }
    }
  }
}
#root {
  .table-admin__row-drag-drop {
    .table-admin {
      &__data {
        box-shadow: none
      }
    }
  }
}