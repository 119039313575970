@import '../../../../assets/styles/variables';

.table-category-rank {
  margin-top: 16px;

  .table-admin {
    &--rank {
      margin-bottom: 24px;
      min-width: 400px;

      .icon-trash {
        cursor: pointer;
      }

      thead {
        tr {
          .table-admin {
            &__title {
              &:first-child {
                min-width: 150px;
              }
              &:last-child {
                width: 30px;
              }
            }
          }

        }
      }
    }
  }

  .input-rank {
    border-radius: 6px;
    padding: 8px 16px;
    line-height: 16px;
    border: transparent;
    width: 70px;
    font-family: $font-family;
    font-size: $font-size-medium;
  }

  .menu-rank-options {
    display: flex;
    flex-wrap: wrap;
    margin: 32px 0;
  }

  .menu-rank-options a {
    background-color: $offwhite;
    border: none;
    color: $mediumgrey;
    cursor: pointer;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin-right: 13px;
    margin-left: -1px;
    margin-bottom: 10px;
    border-radius: 100px;
  }

  .menu-rank-options a.selected {
    color: $ifood;
    border: 1px solid $ifood;
  }

  .icon-button {
    cursor: pointer
  }
}

.modal-view-rank {
  .list-sample {
    display: flex;
    align-items: baseline;

    li {
      margin-right: 16px;
      margin-bottom: 8px;
      color: $mediumgrey;

      &.active {
        color: $black;
      }

      &.more-category {
        padding: 4px 8px;
        margin-right: 0;
        background: $lightgrey;
        border-radius: 2px;
      }
    }
  }
}