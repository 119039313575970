.arrow {
  margin: 0 38px;
  align-self: center;

  &__right {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(-45deg);
    margin-left: 6px;
    border-radius: 2px;
  }
}

.rectangle {
  width: 18px; 
  height: 3px; 
  background: black;
  position: absolute;
  margin-top: -11px;
  border-radius: 2px;
}
