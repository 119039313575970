@import "../../../../assets/styles/variables";

.search-commissions {

  .default-commission {
    .outlined-label-wrap {
      width: 280px;
    }
  }

  .custom-commission{
    margin-top: 56px;
    padding-top: 56px;
    border-top: 1px solid lightgray;

    .section-title{
      margin-bottom: 24px;
    }
    .header-customer-group{
      &__info{
        margin-bottom: 0px;
      }
    }

    .dropdown-label-wrap{
      &__category{
        width: 248px;
        margin-right: 16px;
      }
    }

    .toggle-wrap{
      margin-bottom: 0px;
    }

    .react-toggle {
      cursor: default;
      &:hover:not(.react-toggle--disabled) {
        .react-toggle-track {
          background-color: $regulargrey;
        }
      }

      &-track {
        width: 32px;
        height: 12px;
        background: $regulargrey;

        &-check,
        &-x {
          display: none;
        }
      }

      &-thumb {
        left: 0px;
        top: -2.5px;
        width: 16px;
        height: 16px;
        background: $lightgrey;
        border-color: $lightgrey;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
      }

      &--checked {
        cursor: pointer;
        .react-toggle {
          &-track {
            background: $red-light;
          }

          &-thumb {
            left: 16px;
            border-color: $ifood;
            background: $ifood;
          }
        }

        &:hover:not(.react-toggle--disabled) {
          .react-toggle-track {
            background: $red-light;
          }
        }
      }

      &--focus {
        .react-toggle-thumb {
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
        }
      }

      &:active:not(.react-toggle--disabled) {
        .react-toggle-thumb {
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
        }
      }

      &:hover {
        .react-toggle {
          &-track {
            background: $regulargrey;
          }
        }
      }
    }

  }

  .btn {
    margin-bottom: 48px;
  }

  .header-customer-group {
    &--table-commissions {
      justify-content: inherit;
      align-items: baseline;
    }

    &__new {
      margin-left: 24px;
    }
  }

  .save-commissions{
    margin-top: 56px;
    padding-top: 56px;
    border-top: 2px solid $lightgrey;
  }

  .react-datepicker {
    width: 240px;

    &__input-container {
      .form-brand {
        &__input {
          width: 240px;
        }
      }
    }
  }
  .modal-wrapper {
    .modal-remove{
      width: 345px;

      .modal {
        &__title {
          font-size: $font-size-large;
          font-weight: 500;
        }
        &__close {
          display: none;
        }
      }

      &__actions {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding-left: 15px;

        .link {
          cursor: pointer;
        }
      }
    }
  }

  .MuiBox-root-6, MuiBox-root-16{
    padding: 24px 24px 0px 0px;
  }
}