@import "../../../../assets/styles/style.scss";

.form-variation {
  display: flex;
  flex-direction: column;

  .form-row {
    display: flex;
    align-items: baseline;
    margin-bottom: 24px;
    margin-top: 24px;

    .outlined-label-wrap,
    .dropdown-label-wrap,
    .btn-save {
      width: 176px;
      height: 40px;
      margin-right: 16px;
      margin-bottom: 16px;
    }

    .link {
      margin-inline-start: auto;
    }

    .MuiInputLabel-outlined {
      transform: translate(14px, 17px) scale(1);
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
      padding: 6.5px 4px 6.5px 4px;
    }
  }
}