@import "../../../../assets/styles/variables";

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 443px;

  &__icon {
    height: 80px;
    width: 81.36px;
    margin-bottom: 16px;
  }
  &--success {
    .modal-content__title {
      color: $avocado;
    }
  }
  &--success,
  &--error{
    .modal-content{
      &__title,
      &__description{
        margin: 0 0 16px;
      }
      &-footer{
        margin-top:16px;
      }
    }
  }
  &__title {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: $black;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $heavygrey;
  }
}