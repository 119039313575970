@import "../../../../assets/styles/variables";

.containerOrder{
  width: 100%;
  max-width: 700px;
  border-radius: 6px;

  &__title {
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    font-family: 'SulSans', Helvetica, sans-serif;
  }

  &__data {
    width: 100%;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    font-family: 'SulSans', Helvetica, sans-serif;
  }

  &__modalbtns {
    text-align: right;
    padding-top: 66px;
    justify-content: flex-end;
  }

  &__modalbtns button{
    margin-left: 16px;
  }

  .table-admin{
    min-width: auto;
    width: 100%;
    display: block;
    max-height: 180px;
    overflow-y: auto;
    &__title{
      &:last-child{
        min-width: 100px;
      }
    }
  }

  .buttonOrder{
    font-size: 16px;
  }

  // override default css
  .table-admin__data {
    font-size: 14px;
    min-width: 120px;
  }

  .buttonOrder{
    &.btn-secondary {
      padding: 0 24px;
    }
  }

}