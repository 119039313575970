.form-admin {
  &--commissions {
    margin-bottom: 56px;

    .dropdown-label-wrap,
    .outlined-date,
    .outlined-label-wrap {
      width: 240px;
      margin-right: 0px;

      &__category {
        width: 400px;
      }
    }

    .product-name {
      .outlined-label-wrap {
        width: 400px;
      }
    }

    .react-datepicker {
      width: 240px;

      &__input-container {
        .form-brand {
          &__input {
            width: 240px;
          }
        }
      }
    }
  }
}