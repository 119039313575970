@import '../../../assets/styles/variables.scss';

.pagination-ifoodshop {
    display: flex;
    margin: 0;
    padding: 0;
    background: $offwhite;
    color: $heavygrey;
    list-style: none;

    &__item {
        margin-right: 0;
        padding: 5px 0;

        &-link {
            padding: 5px 8px;
            cursor: pointer;
            line-height: 22px;
        }
    }
    .active {
        color: $ifood;

        &:focus {
            outline: none;
        }
    }
    .previous,
    .next,
    .break {
        padding: 5px 8px;
    }
    .previous-arrow {
        transform: rotate(180deg);
    }
}

.search-customer-paginate {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}