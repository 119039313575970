.pix-chargeback {
    &__button {
        color: #EA1D2C;
        background-color: #fff;
        border: none;
        text-decoration: underline;
        font-size: 16px;
        margin-left: -6px;

        &:hover {
            color: #BC002D;
            cursor: pointer;
        }
    }
}