@import "../../../assets/styles/variables";

.edit-offer {
  .form-register {
    &--category {
      .input {
        margin-bottom: 24px;
        width: 239px !important;
      }

      .toggle-wrap {
        display: flex;
        align-items: center;
        &__label {
          margin-right: 16px;
        }
      }

      .dropdown-label-wrap {
        width: 200px;
        margin-bottom: 24px;
      }

      .stock {
        .outlined-label {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          width: 200px;
          margin: 0px 0px 24px 0px;
          height: 40px;
          margin-bottom: 24px;
        }
      }
    }
  }

  .header-customer-group {
    &--special-price {
      margin-top: 48px;
      margin-bottom: 0;

      .header-customer-group {
        &__info {
          margin-bottom: 32px;
        }
      }
    }
  }

  .header-product {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    margin-left: 16px;

     &__info {
       margin-bottom: 0px;
    }
  }

  .link__special-price {
    position: absolute;
    right: 200px;
    top: 62px;
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
  }

  .header-customer-group {
    &--progressive-price {
      margin-top: 48px;
      margin-bottom: 0;

      .header-customer-group {
        &__info {
          margin-bottom: 32px;
        }
      }
    }
  }
  .link__prog-price {
    margin-bottom: 24px;
  }

  .modal__prog-price {
    width: 300px;
    // height: 410px;
    margin-left: 0px;
    max-height: 500px;
    padding-top: 16px;
    .dropdown-label-wrap,
    .outlined-label-wrap {
      width: 200px;
      margin-bottom: 24px;
    }
  }

  .modal__special-price {
    width: 547px;
    margin-left: 0px;
    max-height: 500px;
    padding-top: 16px;
    overflow-y: inherit;

    .dropdown-label-wrap,
    .outlined-label-wrap {
      width: 210px;
      margin-bottom: 32px;
    }
  }

  .table-admin {
    margin-bottom: 40px;
  }

  .container {
    height: 100%;
    background-color: #fff;
    width: 585px;
    margin: 32px;

    //styleName: Heading/Small/Small - Medium 18|24;
    h2 {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
    h3 {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }

  .subtitle-container {
    margin-left: 33px;
    margin-right: 33px;
    width: 494px;
    margin-bottom: 16px;

    h4 {
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .second {
    display: flex;
    justify-content: flex-start;
    margin-top: 16px;
    margin-left: 0;
  }
  .subtitle {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 24px;
  }
  .condition-container {
    margin-bottom: 0px;
  }
  .card-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 504px;
    margin-top: 16px;
    padding: 16px;
  }
  .price-type {
    &:hover {
      cursor: pointer;
      border-color: red;
    }
  }
  .price-title {
    h2 {
      margin-top: 0px;
    }
  }
  .button-container {
    display: flex;
    align-content: flex-end;
    justify-content: flex-end;
    margin-top: 32px;
    margin-bottom: 32px;
    width: 504px;
  }
  .header-drawer {
    display: flex;
    align-items: center;
    margin-top: 43px;
    .icon {
      cursor: pointer;
      margin: 32px;
    }
    h3 {
      //styleName: Medium / 20|24;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
}
