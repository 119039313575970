@import "../../../../../assets/styles/variables";

.contracts-list {
  &__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin: 0 0 8px 0;
    color: $heavygrey;
  }

  &__subtitle {
    margin: 0;
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: $heavygrey;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  &__items {
    cursor: pointer;
    overflow: auto;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &__items::-webkit-scrollbar {
    display: none;
  }

  &__items &__item:last-child {
    margin-bottom: 0;
  }

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    padding: 24px 18px 24px 14px;
    max-width: 642px;
    border: 1px solid;
    border-radius: 5px;
    border-color: $mediumgrey;

    &:hover,
    &--active {
      border-color: $ifood;
      cursor: pointer;
    }

    &__container {
      display: flex;
    }

    .logistic-img {
      width: 64px;
      margin-right: 32px;

      &--default{
        height: 64px;
        padding: 15px 12px 15px 13px;
        border: 1px dashed $regulargrey;
      }
    }

    .checkbox-ifood {
      margin-right: 15px;
      &__seller{
        margin-right: 25px;
      }
    }

    &-title {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;

      &:hover {
        cursor: pointer;
      }
    }

    &-resume {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;

      &:hover {
        cursor: pointer;
      }
    }
    &--disabled {
      &:hover {
        cursor: pointer;
        border-color: $mediumgrey;
      }
    }
  }

  &__description {
    display: flex;
    flex-direction: column;

    .file-download {
      margin-top: 20px;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      position: relative;

      &:hover {
        cursor: pointer;
      }
      .link {
        z-index: 9999;
      }
    }
  }

  .next-step {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
    width: 642px;
  }
}
