.pix-chargeback-request {
    width: 700px;
    max-width: 100%;

    .outlined-label-wrap {
        width: 100%;
    }

    &__info-reason {
        margin-top: 24px;
    }


    .outlined-textarea {
        width: 100%;
        margin-top: 24px;
    }

    &__button-wrapper {
        display: flex;
        margin-top: 26px;
        gap: 16px;
        justify-content: flex-end;
    }

    &__close-button {
        border: 1px solid;
        color: #EA1D2C;
    }
}