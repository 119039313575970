.product-specifications {
  .list-fields {
    margin-bottom: 24px;
    width: 250px;
  }

  .icon-trash {
    margin-left: 210px;
  }

  &__autocomplete {
    max-width: 252px;

    .outlined-label-field:not(textarea),
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
      height: auto;
      margin-bottom: 16px;
    }

    .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
      padding-right: 44px;
    }
  }
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -8px) scale(1);
}
