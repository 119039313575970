.header-beta {
    position: fixed;
    text-align: center;
    width: 100%;
    background: #fff;
    padding: 16px 0;
    
    a {
        color: #ea1d2c;
    }
}