.modal-suggestion {
  &__content {
    width: 600px !important;
    height: 340px;
  }

  &__title {
    padding: 0 0 26px 24px;
  }

  &__description {
    line-height: 24px;
    padding-left: 29px;
    padding-bottom: 18px;
  }

  &--action {
    width: 590px;
    height: 302px;
  }

  &--btn {
    display: flex;
    justify-content: flex-end;
    padding-right: 60px;
  }

  .btn-primary {
    padding: 16px 16px 16px 42px;
    background: #ea1d2c;
    color: #ffffff;
    display: block;
    align-items: center;
    width: 168px;
    font-size: 18px;
    margin-left: 16px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 500;
  }
}
