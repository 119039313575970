.pix-chargeback-error {
    width: 456px;
    max-width: 100%;
    display: grid;
    justify-items: center;
    gap: 16px;

    &__button {
        margin-top: 20px;
        border: 1px solid;
        color: #EA1D2C;
        font-weight: bold;
    }
}