.audit-history {
    &__table {
        table tbody tr:nth-child(odd) td:first-child {
            background: #F7F7F7;
        }
        table tbody tr td:first-child {
            border-right: 1px solid #A6A6A6;
            padding: 8px 16px;
        }
    }
    &__text{
        margin: 0;
        &--limit{
            max-width:490px;
            word-break: break-word;
        }
    }
}