@import "../../../../../assets/styles/style.scss";

.accessData {
  width: 440px;

  &__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 300;
    margin: 32px 0;
  }

  &__email, &__password {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__link {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }

  &::after {
    content: '';
    display: block;
    width: 440px;
    height: 1px;
    background-color: $regulargrey;
    margin-top: 32px;
  }
}

#accessInfoTooltip {
 .MuiTooltip-tooltip {
    background-color: #3e3e3e;
    color: #fff;
    font-size: 14px;
    font-family: 'Arial';
    font-weight: 400;
    font-style: normal;
    line-height: 20px;
    padding: 8px 0px 8px 12px;
  }
}

#icon-access-info {
  position: absolute;
  margin-left: 5px;
  margin-top: 3px;
}

