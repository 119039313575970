@import '../../../assets/styles/style.scss';

.brand-reg {
  &__fields {
    width: 240px;

    .outlined-label-wrap{
      margin-right: 0;
      margin-bottom: 24px;
    }

    .dropd-input {
      &__state {
        width: 100%;
        min-width: auto;
      }
    }
  }

  &__brand {
    margin-top: 48px;
    margin-bottom: 8px;
  }

  &__text {
    margin-bottom: 40px;
    margin-top: 0px;
    text-align: left;
    font-size: $font-size-large;
    color: $heavygrey;
    min-width: 176px;
  }

  .btn {
    margin-top: 32px;
  }

  &__path-btn {
    position: absolute;
    margin-left: -66px;
    margin-right: 0px;
    margin-top: 48px;
    width: 10px;
    height: 40px;
  }
  .modal-wrapper{
    z-index: 99999;
  }
}
