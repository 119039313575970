@import '@assets/styles/variables';

.form-admin {
  margin-top: 48px;
  width: max-content;

  &__group {
    width: max-content;

    &-address {
      width: 38em;
    }
    
    &-number {
      width: 7em;
    }
    
    &-complement {
      width: 15em;
    }
    
    &-neighbourhood {
      width: 30em;
    }
    
    &-postal-code {
      width: 10em;
    }
    
    &-reference {
      width: 19em;
    }
    
    &-city {
      width: 20em;
    }
    
    &-state {
      margin-right: 16px;
      width: 19em;
    }
    
    &-country {
      width: 20em;
    }

    &-submit{
      float: right;
      margin-right: 16px;
    }

    &-label-error {
      align-self: flex-end;
      display: block;
      margin: 4px;
      font-size: $font-size-small;
      color: $ifood;
    }
  }
}
