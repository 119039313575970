.drawer-container {
  --transition-speed: 0.3s;
}

.drawer {
  background: #fff;
  height: 100%;
  position: fixed;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  z-index: 1000;
  top: 0px;
  overflow-y: scroll;
  width: 585px;

  &.right {
    right: 0;
    transform: translateX(100%);
    .modal-wrapper{
      justify-content: flex-end;
    }
  }

  &.top {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    transform: translateY(-100%);
    height: 40%;
  }

  &.bottom {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    transform: translateY(100%);
    height: 100vh;
  }
}
.drawer-container.open {
  display: block;
}
.drawer-container.open .right {
  animation: slide-in-from-right 0.3s forwards;
}

.drawer-container.open .top {
  transform: translateY(0);
}

.drawer-container.open .bottom {
  animation: slide-in-from-bottom 0.3s forwards;
}

.backdrop {
  visibility: hidden;
  opacity: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity var(--transition-speed) ease,
    visibility var(--transition-speed) ease;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  pointer-events: none;
  z-index: 0;
}

.drawer-container.open {
  .drawer {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  .backdrop {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    z-index: 999;
  }
}

@keyframes slide-in-from-right {
  100% {
    transform: translateX(0%);
  }
}

@keyframes slide-in-from-bottom {
  100% {
    transform: translateY(0%);
  }
}
