.header-customer-group {
    .header-search-order{
        &__action {
            padding: 0 8px;
        }
    }
}

#listLinksHeader {
    width: 194px;
    top: inherit;
    right: auto;
}

#chevronDownHeaderOrder {
    path {
        fill: #3E3E3E;
    }
}