@import "../../assets/styles/variables";

.modal .modal-alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    margin: -16px 0 16px;
    font-size: 20px;
    font-weight: 500;
    color: $black;
  }

  &__description {
    color: $heavygrey;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 24px;
  }

  .outlined-label-wrap {
    width: 326px;
    margin: 0 0 24px 0;
  }
  .btn {
    width: 72px;
    height: 48px;
  }

  &--action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
