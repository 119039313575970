@import '../../../../assets//styles/variables';

.modal-content-order {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  width: 443px;
  font-family: 'SulSans', Helvetica, sans-serif;

  &__check {
    height: 80px;
    width: 81.36px;
  }

  &__title{
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    color: #50A773;
  }

  &__description {
    font-size: 16px;
    line-height:24px;
    text-align: center;
    color: $heavygrey;
  }

  & button {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    font-family: 'SulSans', Helvetica, sans-serif;
  }

}