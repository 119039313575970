.modal-suggest {
  .modal-wrapper > .modal {
    max-width: 590px;
    line-height: 24px;
  }
}

.search-suggest {
  .header-customer-group--src {
    .header-customer-group {
      &__new {
        display: flex;
        align-items: center;
        .import-images {
          margin-left: 16px;
        }

        .options-link {
          position: relative;
          margin-left: 12px;

          .modal__body {
            .options-btn {
              display: flex;
              flex-direction: column;

              .link {
                padding: 0px 0px;
                margin: auto;
              }
            }
          }
        }
      }
    }
  }

  .modal-wrapper > .modal {
    max-width: 590px;
    line-height: 24px;
  }

  .table-admin {
    &--sample {
      margin-bottom: 24px;

      thead {
        .table-admin {
          &__title {
            &:first-child {
              min-width: 70px;
            }

            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5) {
              min-width: 150px;
            }
          }
        }
      }
    }
  }
}
