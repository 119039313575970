@import '../../../assets/styles/variables';

.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: $white;
  color: $white;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: .5s;

  &::before, &::after {
	content: '';
	display: inline-block;
	position: absolute;
	top: 0;
  }

  &::before {
	left: -15px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: $white;
	color: $white;
	animation: dot-flashing 1s infinite alternate;
	animation-delay: 0s;
  }

  &::after {
	left: 15px;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	background-color: $white;
	color: $white;
	animation: dot-flashing 1s infinite alternate;
	animation-delay: 1s;
  }

  &--ifood {
	background-color: $ifood;
	color: $ifood;

	&::before, &::after {
		background-color: $ifood;
		color: $ifood;
	}
  }
}

@keyframes dot-flashing {
  0% {
	  background-color: #ffffffb0;
	}
	50%,
	100% {
		background-color: $white;
  }
}

.button-loader{
    display: flex;
    justify-content: center;
    width: 100%;
}
