@import "../../../../assets/styles/variables";

.order-detail {
  .modal-wrapper {
    .modal {
      &.order-detail {
        &__modal-discount {
          width: 360px;

          .modal {
            &__body {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
.delivery-item-table {
  &__discount {
    padding: 5px;
  }
  .table-admin__data {
    &--disabled {
      color: $mediumgrey;
    }
  }
}

.item-variable-weight-text{
  white-space: nowrap;
  @media (max-width: 999px) {
    white-space: normal;
  }
}

.btn-rupture {
  background-color: transparent;
	border: 0;
	outline: none;
	padding: 0;
	&:hover {
		cursor: pointer;
	}
  svg{
    pointer-events: none;
  }
}

.display-none {
  display: none;
}

.display-flex {
  display: flex;

  > div {
    flex: 1;
  }
}
.discount-colored {
  color: $avocado;
}
.discountLink {
  color: $avocado;
  cursor: pointer;
  text-decoration: underline;
}

.rupture-table__edit {
  display: flex;
  justify-content: center;
  gap: 4px;
  align-items: center;
  width: 100%;
}

.hasHistory {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 960px;
}

.btn-originalOrder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin-top: 24px;
}