.product-specifications {
  .list-fields {
    margin-bottom: 24px;
    width: 250px;
  }

  .icon-trash {
    margin-left: 310px;
  }
}
