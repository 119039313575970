#customerDetailLinks {
  a {
   text-decoration: none;
   color: #717171;

    &:first-child {
       text-decoration: none;
       color: #3E3E3E;
       &:hover {
         text-decoration: none;
         color: #3E3E3E;
         background-color: #fff;
       }
    }

    &:hover {
       text-decoration: none;
       color: #717171;
       background-color: #F2F2F2;
    }
  }

  label {
    text-decoration: none;
    color: #717171;

    &:hover {
       text-decoration: none;
       color: #717171;
       background-color: #F2F2F2;
    }
  }
}

.header-customer-group {
  .modal-wrapper {
    .modal {
      min-width: 450px;
      padding: 32px;

      &__title {
        padding-left: 0 !important;
      }
    }
  }
}