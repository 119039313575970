/*Escopo modal*/
.modal-steps-order {
  .modal-wrapper & {
    .modal__body {
      margin: 0;
    }
  }
  .modal__title {
    margin: 0 0 40px;
    line-height: 32px;
  }

  .modal-content {
    .table-admin--scroll-y {
      width: 100%;
      margin-top: 24px;
    }
  /*
    Modais content sucess e error sendo alterados no Steps order
  */
    &--success,
    &--error {
      margin: 0 auto;
    }
    &--success {
      width: 504px;
      .modal-content__description {
        max-width: 356px;
      }
    }
    &--error {
      width: 590px;
      .modal-content-footer{
        margin-top: 64px;
      }
    }
  }
}

/*Escopo modal Conteúdo*/
.content-steps-order {
  width: 100%;
  max-width: 700px;
  min-width: 700px;
  .modal-content-footer{
    margin-top: 64px;
  }
}
