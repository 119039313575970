@import '../../../../assets/styles/variables';

.form-register {

  &__tags {
    max-width: 360px;
    margin: 8px 0 16px;
  } 

  &--specification {

    .input {
      margin-bottom: 24px;
      width: 200px;
    }

    .dropdown-label-wrap {
      width: 200px;
      margin-bottom: 24px;
    }
    .check-filter{
      margin-bottom: 24px;
    }

    .toggle-wrap {
      margin-bottom: 24px;

      &__label {
        margin-right: 12px;
      }
    }
  }
}