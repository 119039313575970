@import '../../assets/styles/style.scss';

.header-customer-group {
  &.header-customer-group--src {
    .header-customer-group {
      &__form {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        width: 100%;

        &-input {
          display: flex;
          align-items: center;
          margin-bottom: 24px;
        }
        &-see-all {
          display: flex;
          align-items: center;

          .see-all-label {
            color: $heavygrey;
            cursor: pointer;
          }
        }
        .outlined-label-wrap, {
          margin-right: 16px;
        }
        .dropdown-label-wrap {
          min-width: 170px;
          margin-right: 26px;
        }
        .checkbox-ifood {
          margin-right: 10px;
        }
      }
      &__actions {
        width: 100%;
      }
    }
  }
}