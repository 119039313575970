.identify-product {
  .MuiFormLabel-root,
  .MuiInputLabel-root {
    padding-top: 6px;
  }
  .MuiInputBase-root {
    height: 48px;
  }
  .suggestion-title {
    padding-top: 52px;
  }
  .suggestion-description {
    padding-top: 12px;
  }
  .suggestion-link,
  .link {
    padding-top: 8px;
    font-size: 18px;
  }

  .button-suggestion {
    padding-top: 44px;

    .button,
    .button-suggestion,
    .btn-secondary,
    .suggestion-register {
      display: grid !important;
      align-items: center;
      width: 168px;
      height: 48px;
      font-size: 18px;
      margin-left: 16px;
      text-align: center;
      border-radius: 5px;
    }
  }
  .btn--red {
    margin-left: 16px;
    text-decoration: none;

    &.active,
    &:hover {
      &:not([disabled]) {
        cursor: pointer;
      }
    }

    &[disabled] {
      cursor: default;
      background: #dcdcdc;
      color: #a6a6a6;
      border: none;
    }
  }
}
