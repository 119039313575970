@import "../../../../../../assets/styles/variables";


.request-vehicle {
  display: flex;
  flex-direction: column;
  min-width: 520px;
  width: auto;
  
  &__header {
    display: flex;
    margin-bottom: 24px;
    
    .button{
      border: none;
      &--white{
        border: none;
      }
    }

    &-confirmation {
      display: flex;
      justify-content: space-between;
      margin-bottom: 46px;
    }
  }
  
  &__subtitle {
    margin-bottom: 12px;
  }

  &__order {
    margin-bottom: 24px;
    line-height: 24px;
    
    &-first {
      margin-bottom: 24px;
    }
  }

  &__text {
    line-height: 24px;
  }
  
  &__instructions {
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    line-height: 24px;
  }
  
  &__topic {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin: 0 0 11px 0;
    line-height: 24px;
  }
  
  &__modal {
    align-self: flex-end;
    margin-bottom: -18px;
    margin-right: -24px;
    
    &::after {
      content: "";
      display: inline-block;
      height: 336px;
      width: 291px;
      margin-left: 26px;
      background-repeat: no-repeat;
      background-image: url("../../../../../../assets/images/order/requestVehicle/Group\ 96.svg");
    }
  }

  &__confirmation {
    display: flex;
    flex-direction: column;
    margin: 0 48px 0 47px;
    align-items: center;
    text-align: center;
    line-height: 24px;
    
    &-first {
      margin-bottom: 32px;
    }

    &-second {
      margin-bottom: 36px;
    }
  }
  .icon-check {
    align-self: center;
    height: 76px;
    width: 76px;
    margin-bottom: 12px;
  }
  
  &__next {
    &-right {
      align-self: flex-end;

      .button {
        width: 193px;
        align-content: center;
        justify-content: center;
        font-size: 18px;
      }
    }
    
    &-left {
      align-self: flex-start;
      
      .button {
        width: 193px;
        align-content: center;
        justify-content: center;
        font-size: 18px;
      }
    }
  }
}

.modal-request {
  display: flex;
  
  &__column {
    flex-direction: column;
  }
  
  .request-column {
    display: flex;
    
    .request-vehicle{
      min-width: inherit;   
    }
  }
}