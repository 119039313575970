@import '../../../../../assets/styles/style.scss';

.header-customer-group {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 40px;

  &--detail {
    .header-customer-group {
      &__actions {
        align-items: center;

        &-right {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &__info {
    margin-bottom: 21.5px;
    margin-left: 1.5rem;
    display: flex;
    align-items: center;
    gap: 16px;

    h2 {
      margin: 0;
    }
  }
  &__description {
    margin: 0 0 0.5rem;
    font-size: $font-size-large;
    color: $heavygrey;
  }
  &__src {
    display: flex;
    align-items: center;
    gap: 1rem;

    button, input {
      width: 192px;
      display: flex;
      gap: 15px;
    }

    &__export {
      background: $white;
      color: $ifood;
      font-weight: bold;

      &__disabled {
        background: #F2F2F2;
        border: 1px solid #DCDCDC;
        color: #A6A6A5;
        font-weight: bold;
      }
    }

    &__import {
      font-weight: bold;

      label {
        color: $white;
        cursor: pointer;
      }

      input {
        display: none;
      }
    }

    &__import-container {
      position: relative;
      &__options{
        height: 100px;
        width: 201px;
        border: 1px solid #DCDCDC;
        border-radius: 6px;

        position: absolute;
        z-index: 9;
        top: 58px;
        background: $white;

        button {
          height: 48px;
          width: 100%;
          border: none;
          background: $white;
          color: #717171;

          &:hover {
            background: #F2F2F2;
            color: #3E3E3E;
          }
        }

      }
    }
  }
  &__actions {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #F2F2F2;
    padding-bottom: 32px;

    &.disabled {
      color: lighten($mediumgrey, 10%);
    }

    &-link {
      margin-right: 32px;
      cursor: pointer;

      &.disabled {
        color: lighten($mediumgrey, 10%);
        cursor: default;
        text-decoration: none;
      }
      &.link-see-more {
        position: relative;
        padding: 8px 65px 8px 16px;
        margin-right: 0;
        text-decoration: none;
        line-height: 24px;
        color: $black;
        border: 1px solid $regulargrey;
        border-radius: 4px;

        &:hover {
          color: $black;
        }

        .chevron-down {
          position: absolute;
          right: 16px;
          top: 50%;
          width: 16px;
          transform: translate(0, -50%);
          transition: all 0.3s;

          path {
            fill: $ifood;
          }
          &.open {
            transform: rotate(180deg) translate(0, 50%);
          }
        }
      }
    }
    &-import {
      display: none;
    }
    &-more {
      position: absolute;
      top: 100%;
      right: 0;
      display: flex;
      flex-direction: column;
      width: 192px;
      background: white;
      border: 0.5px solid $lightgrey;
      z-index: 9999;
      border-radius: 4px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.24);

      .link {
        padding: 16px 15px;
        margin-right: 0;
        border-bottom: 1px solid $lightgrey;
        font-size: $font-size-medium;
        line-height: 16px;

        &--disabled {
          &:hover {
            color: $mediumgrey;
          }
        }
      }
    }
  }

  &__status {
    padding: 6px 16px 4px;
    margin-right: 24px;
    font-size: 12px;
    font-weight: bold;
    color: white;
    border-radius: 34px;

    &.habilitado {
      background: $white;
      color: $avocado;
      border: 1px solid $avocado;
    }
    &.desabilitado {
      background: $white;
      color: #A6A6A5;
      border: 1px solid #DCDCDC;
    }
  }

  //MODAL REMOVE CUSTOMER
  .modal-wrapper {
    .modal-remove{
      width: 500px;

      .modal {
        &__title {
          font-size: 24px;
          line-height: 32px;
          font-weight: 500;
          padding-left: 0 !important;
          font-weight: 500;
          width: 380px;
        }
        &__close {
          right: 24px !important;
        }

        &__body {
          margin-top: 32px;
        }
      }

      &__actions {
        display: flex;
        gap: 16px;
        justify-content: flex-end;
        align-items: baseline;
        padding-left: 15px;
        margin-top: 32px;

        &__cancel {
          background: $white;
          color: $ifood;
        }

        button {
          height: 48px;
          font-weight: bold;
          margin: 0 !important;
        }
      }
    }
  }

}

.subHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  .divider {
    display: flex;
    gap: 1rem;
    max-height: 48px;

    button {
      background: $white;
      color: $ifood;
      font-weight: bold;
    }
  }

  &__search {
    width: 420px;
    height: 48px;
    border-radius: 6px;
    border: 1px solid #DCDCDC;
    padding: 12px 8px;

    display: flex;
    align-items: center;

    .icon-search {
      path {
        fill: $ifood;
      }
    }

    .clear {
      cursor: pointer;
    }

    input {
      background-image: none !important;
      background-color: $white;
      width: 100%;
      padding-right: 15px !important;
      
      &::placeholder {
        color: #A6A6A5;
        font-size: 1rem;
        line-height: 1.5rem;
      }
    }
  }

  &__actions {
    display: flex;
    gap: 1rem;
    width: auto;

    .button--disabled {
      background: none !important;
      color: #A6A6A5;
      cursor: pointer !important;
    }

    button {
      width: 192px;
      height: 48px;

      font-size: 1rem;
      font-weight: 700;

      border: none;
      background: $white;
      color: $ifood;

      svg {
        margin-right: 11px;
      }
    }
  }
}

.results-label {
  margin-bottom: 1rem;
}

.input {
  &-secondary {
    padding: 8px 15px;
    font-size: $font-size-large;
    background: $lightgrey;
    color: $heavygrey;
    border: none;
    box-shadow: none;

    &.input {
      &__icon {
        position: relative;
        padding-right: 40px;
        background-repeat: no-repeat;
        background-position: calc(100% - 17px);

        &-src {
          background-image: url('../../../../../assets/icons/search.svg');
        }
      }
    }
    &:focus {
      outline: 0;
    }
  }

}

.fallBackModal {
  width: 500px;

  .modal__title {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    font-weight: 500 !important;
  }

  .fallback {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  
    span {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  
    div {
      width: 100%;
      display: flex;
      justify-content: flex-end;
  
      button {
        max-width: 133px;
        height: 48px;
        align-items: center;
        justify-content: center;
        font-weight: bold;
      }
    }
  }
}

.modal-addCostumer {
  max-width: 500px;
  &__title {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    font-weight: 500 !important;
  }

  &__close {
    right: 32px !important;
    top: 38px !important;
  }

  &__body {
    .description {
      display: flex;
      flex-direction: column;
      gap: 32px;
      align-items: flex-end;
    }
  }
}

button {
  margin-right: 0 !important;
}

.form-add-customer__actions__cancel {
  margin-right: 1rem !important;
}

.description-container {
  display: flex;
  gap: 0.5rem;

  .header-customer-group__description{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 350px;
  }

  .see-more {
    cursor: pointer;
    text-decoration: underline;
    color: #717171
  }
}