@import "../../../../../assets/styles/style.scss";

.editSeller {
  width: 560px;
  height: 100vh;
  position: fixed;
  right: 0;
  top: 0;
  background-color: $white;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.24);

  &__data {
    padding-top: 112px;
    width: 408px;
    margin: 0 auto;

    &--title {
      display: flex;
      flex-grow: 1;

      svg {
        margin-top: 19px;
        cursor: pointer;
      }

      h2 {
        padding-left: 104px;
        font-size: 20px;
        line-height: 24px;
        color: $black;
      }
    }

    &--input {
      margin-top: 56px;
      display: flex;
      flex-direction: column;
      height: 265px;
      justify-content: space-between;
    }

    &--inputAccess {
      margin-top: 56px;
      display: flex;
      flex-direction: column;
      height: 216px;
      justify-content: space-between;
    }

    &--button {
      display: flex;
      justify-content: flex-end;
      margin: 40px 16px;
    }

    &--eyeContainer {
      max-height: 40px;
    }
  }
}
