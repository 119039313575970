.form-register {
  border: 1px solid #F2F2F2;
  border-radius: 6px;
  padding: 24px;
  max-width: 572px;
  &__input-group {
    margin-bottom: 24px;

    span{
      margin-bottom: 4px;
    }

    .outlined-label-wrap{
      width: 100%;

      .input-blur-error {
        border: 1px solid #FF7752;
      }

      .input {
        margin-top: 6px;
      }
    }

    .wrap {
      display: flex;
      align-items: center;
      gap: 8px;

      .dropdown-label-wrap{
        .dropdown-ifood{
          .dropdown-ifood__control{
            border: 1px solid #DCDCDC;
            border-radius: 8px;
            height: 48px;
            max-width: 150px;

            .Dropdown-arrow-wrapper{
              span {
                margin: auto;
                background: url('../../assets/icons/chevron-down-red.svg') no-repeat;
              }
            }
          }
        }
      }
    }
  }

  .input {
    width: 100%;
  }

  .dropdown-label-wrap {
    width: 200px;
  }

  button {
    position: absolute;
    right: 65px;
    top: 47px;
  }
}