@import "../../assets/styles/variables";

.check-component {
  display: flex;
  margin: 0 8px;

  .right {
    width: 8px;
    height: 3px;
    margin-top: 2px;
    background: $ifood;
    border-radius: 2px;
    transform: rotate(45deg);
  }

  .left {
    width: 12px;
    height: 3px;
    margin-left: -5px;
    background: $ifood;
    border-radius: 2px;
    transform: rotate(-45deg);
  }
}
