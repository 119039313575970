@import "../../assets/styles/style.scss";

.dropdown-label-wrap {
  position: relative;
  transition: border-color 0.25s;

  .dropdown-label {
    position: absolute;
    top: 0;
    left: 12px;
    display: inline-block;
    width: auto;
    margin: 0;
    padding: 2px;
    transition: transform 0.25s, opacity 0.25s, padding 0.25s ease-in-out;
    color: $mediumgrey;
    background: white;
    transform: translateY(-50%);
    z-index: auto;
  }

  .dropdown-ifood {
    &__control {
      min-height: 40px;
      padding: 12px 35px 8px 15px;
      cursor: pointer;
      border-radius: 5px;

      &.disabled,
      &.Dropdown-disabled {
        cursor: default;
        background: #F2F2F2;
        border-color: #DCDCDC;
        color: silver;
        .dropdown-ifood {
          &__arrow {
            opacity: 0.3;
          }
        }
      }
    }
    &__arrow {
      top: 50%;
      width: 16px;
      height: 10px;
      background: url("../../assets/images/arrow-down.png") no-repeat;
      background-size: contain;
      border: none;
      transform: translateY(-50%);
      transition: all 0.3s;
    }
    &__menu {
      z-index: 9999;
      border: 1px solid $regulargrey;
      border-radius: 4px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.24);

      .Dropdown-option {
        padding: 12px 11px;
        border-bottom: 1px solid $lightgrey;
        color: $heavygrey;

        &:last-child {
          border-bottom: none;
        }
        &.is-selected {
          background: white;
          color: $black;
          border-right: 1px solid $regulargrey;
        }
        &:hover {
          background: $lightgrey;
        }
      }
    }

    &.is-open {
      .dropdown-ifood {
        &__arrow {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }

    &.MuiAutocomplete-root {
      .dropdown-ifood {
        &__arrow {
          transform: translateY(0);
        }
      }
    }
  }
}
