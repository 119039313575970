@font-face {
  font-family: 'SulSans';
  src: url('../fonts/SulSans/SulSans-Light.woff2') format('woff2'), url('../fonts/SulSans/SulSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SulSans';
  src: url("../fonts/SulSans/SulSans-Medium.woff2") format("woff2"), url("../fonts/SulSans/SulSans-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SulSans';
  src: url("../fonts/SulSans/SulSans-Regular.woff2") format("woff2"), url("../fonts/SulSans/SulSans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SulSans';
  src: url("../fonts/SulSans/SulSans-Bold.woff2") format("woff2"), url("../fonts/SulSans/SulSans-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SulSans';
  src: url("../fonts/SulSans/SulSans-BoldItalic.woff2") format("woff2"), url("../fonts/SulSans/SulSans-BoldItalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

body {
  font-family: 'SulSans', Helvetica, sans-serif;
  font-weight: normal;
  font-size: $font-size-large;
  color: #3e3e3e
}