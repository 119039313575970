@import '../../../../assets/styles/style.scss';

.order-detail {
  &__history {
    margin-bottom: 56px;

    &-audit {
      padding: 24px;
      text-align: center;
    }

    .order-detail__history-link.link {
      font-size: 14px;
      color: $heavygrey;
    }
  }
  &__steps {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &.active {
        .order-detail {
          &__steps {
            &-box-icon {
              border: 1px solid $avocado;
            }
            &-label {
              color: $avocado
            }
          }
        }
      }
    }
    &-box-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 78px;
      height: 78px;
      margin-bottom: 14px;
      border-radius: 78px;
      background: $offwhite;
    }
    &-label {
      width: 104px;
      margin-bottom: 14px;
      font-size: $font-size-medium;
      font-weight: 300;
      line-height: 16px;
    }
    &-info {
      width: 67px;
      font-size: $font-size-small;
      font-weight: 300;
      line-height: 16px;
      color: $heavygrey;
    }
    &-arrow {
      align-self: flex-start;
      margin-top: 39px;
    }
  }
}