@import "../../../../assets/styles/style.scss";

.header-order {
  max-width: 1460px;
  .togglebutton-ifood {
    flex: 100%;
    margin-bottom: 16px;
  }
  .outlined-label-wrap {
    width: 232px;
  }
  .autocomplete-ifood {
    width: 232px;
  }
  .react-datepicker {
    &__input-container {
      .form-brand__input {
        margin-right: 0;
        width: 232px;
      }
    }
  }
  &.header-customer-group.header-customer-group--src {
    .header-customer-group__form {
      display: block;
      .dropdown-label-wrap {
        margin-right: 16px;
      }
      .header-customer-group__form-input {
        display: grid;
        flex-wrap: wrap;
        gap: 16px;
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
      
      }
    }
    display: block;
  }
  .dropdown-ifood {
    &__control {
      width: 233px;
    }
  }

.toggle-wrap {  
  display: flex;

  .react-toggle {
      cursor: default;
      &:hover:not(.react-toggle--disabled) {
        .react-toggle-track {
          background-color: $regulargrey;
        }
      }

      &-track {
        background: $regulargrey;
        width: 50px;
        height: 18px;

        &-check,
        &-x {
          display: none;
        }
      }

      &-thumb {
        top: -4px;
        width: 24px;
        height: 24px;
        background: $lightgrey;
        border-color: $lightgrey;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
      }

      &--checked {
        cursor: pointer;
        .react-toggle {
          &-track {
            background: $red-light;
          }

          &-thumb {
            border-color: $ifood;
            background: $ifood;
          }
        }

        &:hover:not(.react-toggle--disabled) {
          .react-toggle-track {
            background: $red-light;
          }
        }
      }

      &--focus {
        .react-toggle-thumb {
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
        }
      }

      &:active:not(.react-toggle--disabled) {
        .react-toggle-thumb {
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
        }
      }

      &:hover {
        .react-toggle {
          &-track {
            background: $regulargrey;
          }
        }
      }
    }
    .toggle-wrap__label {
      margin: 0px 0px 0px 12px;
    }
  }
}

.form-order {
  .react-datepicker {
    max-width: 250px !important;
  }
}

.export-order-admin-grid {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.export-order-admin-icon {
  position: absolute;
  background: url("../../../../assets/icons/Export.svg") no-repeat;
  width: 18px;
  height: 16px;
  color: red;
}

.contentLinkExport {
  margin: 0px 12px;
}

.linkExport {
  margin-left: 12px;
}
