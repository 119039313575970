@import '../../../assets/styles/variables';

.badwords-register {
  .outlined-label-wrap {
    margin-bottom: 24px;
    width: 400px;
  }

  .toggle-wrap{
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    &__label{
      margin-right: 16px;
    }
  }
}
