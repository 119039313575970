.form-edit {
  &__input-group {
    margin-bottom: 24px;
  }

  .input {
    width: 340px;
  }
  .dropdown-label-wrap {
    width: 200px;
  }
}