@import '../../../../../../assets/styles/variables';

.progress {
  display: flex;
  flex-direction: column;
  width: 575px;

  .status {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;
    margin-left: 48px;
    position: relative;

    &:not(:last-child) {
      &:after {
        content: "";
        position: absolute;
        top: 20px;
        left: -1px;
        width: 1px;
        height: 100%;
        background: $heavygrey;
        z-index: -1;
      }
    }
  }

  .steps {
    display: flex;
    width: 20px;
    height: 20px;
    background: $white;
    border: 2px solid $mediumgrey;
    border-radius: 60%;
    margin: 0 0 0 -10px;

    &--success {
      display: flex;
      width: 20px;
      height: 20px;
      background: $avocado;
      background-image: url("../../../../../../assets/icons/order/check_bar.svg");
      border: 2px solid $avocado;
      border-radius: 60%;
      margin: 0 0 0 -10px;
    }

    &--failed {
      display: flex;
      width: 20px;
      height: 20px;
      background: $ifood;
      background-image: url("../../../../../../assets/icons/order/failed_bar.svg");
      border: 2px solid $ifood;
      border-radius: 60%;
      margin: 0 0 0 -10px;
    }

    &__title {
      line-height: 24px;

      &--success {
        color: $avocado;
      }

      &--failed{
        color: $ifood;
      }
    }

    &__date {
      line-height: 18px;
      color: $mediumgrey;
    }

    &__information {
      display: flex;
      flex-direction: column;
      margin-left: 16px;
    }
  }
}
