@import "../../../assets/styles/style.scss";

.search-brand {

  .header-customer-group__info{
    margin-bottom: 40px;
  }

  .table-admin {
    &--sample {
      thead {
        .table-admin {
          &__title {
            &:first-child {
              min-width: 100px;
            }
            &:nth-child(2) {
              min-width: 250px;
            }
          }
        }
      }
    }
  }
}
.form-brand {
  display: flex;
  flex-direction: column;

  .form-row {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px;
  }

  .dropdown-label-wrap {
    width: 170px;
    margin-right: 26px;
  }
  .checkbox-ifood {
    margin-right: 16px;
  }

  &__input {
    font-family: $font-family;
    padding: 11px;
    margin-right: 15px;
    border: 1px solid $regulargrey;
    border-radius: 5px;
    color: $black;
    font-size: 16px;

    &:focus {
      outline: none;
    }
  }

  &__see-all {
    color: $ifood;

    &:hover {
      color: $ifood;
    }
  }
  &__btn-filter {
    margin-top: 20px;
  }
}
.react-datepicker {
  font-family: $font-family;
  color: $black;

  &__header {
    background-color: white !important;
  }
  &__day--selected,
  &__day--keyboard-selected {
    border-radius: 50% !important;
    background-color: #ea1d2c !important;
  }
}
select option {
  background-color: white;
}
