@import '../../../../assets/styles/variables';

.orderDelayedTable {
    .orderDelayedTag {
        padding: 4px 16px 4px 16px;
        font-weight: 700;
        text-align: center;
        border-radius: 38px;
        border: 2px solid;
        color: $mustard;
        background-color: #fff;
    }
}

.orderSearch{
   &--title-search{
      display: flex;
      justify-content: space-between;
      max-width: 1145px;
   }
   &--description-filter{
      margin-bottom: 32px;
   }
   &__link{
      display: flex;
      justify-content: center;
      font-size: 14px;
      line-height: 16px;
      .icon-trash-can{
         width: 13px;
         height: 13px;
         margin-right: 3px;
      }
   }
}

// style tooltip
[id*="-tooltip"]{
   .MuiTooltip-tooltip {
      background-color: #3e3e3e;
      color: #fff;
      font-size: 14px;
      font-family: 'Arial';
      font-weight: 400;
      font-style: normal;
      line-height: 20px;
      padding: 8px 0px 8px 12px;
      margin-bottom: 14px;
    }
  
   .MuiTooltip-arrow {
      color: #3e3e3e;
      bottom: 1px;
   }
}