@import '../../../../../assets/styles/variables.scss';

.table-admin {
  border-spacing: 0;
  width: 100%;
  min-width: 960px;
  &--width-auto{
    min-width: auto;
  }
  &--scroll-y{
    overflow-y: auto;
  }
  &--scroll-y-small{
    max-height: 180px;
  }
  &__title,
  &__data {
    text-align: left;
    padding: 8px 16px;
    border-right: 1px solid $mediumgrey;
    color: $black;
  }
  &__item{
    display:flex;
    align-items: center;
  }
  &__tooltip{
    position: relative;
    padding-right: 20px;
    &-icon{
       position:absolute;
       margin-left: 8px;
       display: inline;
    }
 }

  thead {
    .table-admin {
      &__title {
        font-weight: 500;
        font-size: $font-size-large;

        &:first-child {
          width: 30px;
          border-right: 0;
        }
        &:nth-child(2) {
          width: 70px;
        }
        &:last-child {
          width: 50%;
          border-right: 0px;
        }
      }
    }
  }
  tbody {
    tr {
      .table-admin {
        &__data {
          font-size: $font-size-medium;

          &:first-child {
            padding: 0 9px 0 0;
            border-right: 0;
          }
          &:last-child {
            border-right: 0px;
          }
        }
      }
      &:nth-child(odd) {
        td:not(:first-child) {
          background: $offwhite;
        }
      }
    }
  }
  &--customer-group {
    width: auto;
    min-width: 960px;

    thead {
      .table-admin {
        &__title {
          &:nth-child(2) {
            width: 50px
          }
          &:nth-child(5) {
            width: 170px
          }
        }
      }
    }
  }


  &--sample {
    width: auto;
    &--full{
      width: 100%;
    }
    thead {
      .table-admin {
        &__title {
          padding: 8px 16px;

          &-name {
            min-width: 350px;
          }
          &:first-child {
            width: auto;
            border-right: 1px solid $mediumgrey;
          }
          &:nth-child(2) {
            width: auto;
          }
          &:last-child {
            width: auto;
          }
        }
      }
    }
    tbody {
      tr {
        .table-admin {
          &__data {
            padding: 8px 16px;

            &:first-child {
              padding-left: 16px;
              border-right: 1px solid $mediumgrey;
            }

            &--empty {
              border-right: none;
            }
          }
        }
        &:nth-child(odd) {
          .table-admin__data {
            background: $offwhite;


            &--empty{
              padding: 8px 16px;
              border-right: none;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
  &--specification{
    margin-bottom: 80px;
  }
}
