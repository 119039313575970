// Typography
.text--center {
    text-align: center !important;
}
.text--danger {
	color: $ifood !important;
}
.text--success {
	color: $avocado !important;
}
.text--right {
	text-align: right;
}

// Style
.background--info {
	background-color: $blueberry !important;
}
.background--danger {
	background-color: $ifood !important;
}
.background--warning {
	background-color: $mustard !important;
}
.background--success {
	background-color: $avocado !important;
}