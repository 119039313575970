@import '../../../../assets//styles/style.scss';

.order-detail {
  &__order {
    margin-bottom: 78px;
    border-bottom: 8px solid $lightgrey;
  }
  &__info {
    display: flex;

    &-title {
      font-size: $font-size-title;
      margin: 0;
      margin-right: 174px;
    }
    &-detail {
      display: flex;
      flex-direction: column;
      width: 360px;
    }
    &-item {
      display: flex;
      justify-content: space-between;
      margin: 0 0 16px;
      &:last-of-type{
        margin-bottom: 24px;
      }
    }
    &-link{
      display: flex;
      justify-content: flex-end;
    }
    &-value {
      font-weight: 500;
    }
  }
}