@import '../../assets/styles/variables';

.modal-wrapper {
  .modal{
    &.modal--import-product {
      max-height: 98vh;
      padding: 24px;

      .modal {
        &__body {
          margin-top: 16px;
        }
        
        &__title {
          font-weight: 500;
          font-size: 24px;
          line-height: 32px;
          text-align: left;
          color: $black;
          padding-left: 0;
        }

        &__subtitle {
          color: $heavygrey;
        }
        
        &__import-zone {
          width: 100%;
          padding: 36px 16px;
          margin-bottom: 20px;
          background: $lightgrey;
          border-radius: 6px;
          border: 1px solid $lightgrey;
          transition: all 0.4s;

          &--active {
            background: transparent;
            border: 1px dashed $ifood;
          }

          &__header {
            display: flex;
            justify-content: space-between;
            padding: 0 16px 16px 16px;
            border-bottom: 1px solid $regulargrey;
          }

          &__max-size {
            color: $heavygrey
          }

          &__body {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 40px 0 16px 0;

            .icon {
              max-width: 180px;
            }
          }

          &__info {
            display: flex;
            flex-direction: column;
            text-align: center;
            color: $heavygrey
          }
          
          &__label-import {
            color: $ifood;
            cursor: pointer;
          }

          &__status {
            position: relative;
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            padding: 16px 28px 16px 20px;
            background: white;
            border-radius: 6px;
            overflow: hidden;

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 0;
              height: 4px;
              background: transparent;
            }

            &--success {
              &:after {
                width: 100%;
                background: $avocado;
              }
            }
            &--error {
              &:after {
                width: 100%;
                background: $ifood;
              }
            }

            &__info {
              display: flex;
              align-items: center;
              flex-flow: row wrap;
              justify-content: space-between;
              width: calc(100% - 45px);
            }

            &__title {
              margin: 0;
              color: $heavygrey;
            }

            &__refresh {
              display: flex;
              align-items: center;
              color: $ifood;
              cursor: pointer;

              .icon {
                margin-left: 12px;
                transition: all 0.4s;
              }

              &:hover {
                .icon {
                  transform: rotate(-180deg);
                }
              }
            }

            &__label {
              font-size: 12px;
              width: 100%;
            }

            &__success {
              color: $avocado;
            }

            &__error {
              color: $ifood
            }

            .outlined-label-wrap {
              width: 100%;
              margin-top: 16px;
            }
          }

          .icon {
            &--sheet {
              margin-right: 20px;
            }
          }
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;

      gap: 16px;
    }

    .d-none {
      display: none;
    }
  }
}