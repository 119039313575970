.radio {
  display: block;
  cursor: pointer;
  input {
    display: none;
    & + span {
      line-height: 16px;
      height: 16px;
      padding-left: 16px;
      display: block;
      position: relative;
      &:not(:empty) {
        padding-left: 16px + 8;
      }
      &:before,
      &:after {
        content: "";
        width: 16px;
        height: 16px;
        display: block;
        border-radius: 50%;
        left: 0;
        top: 0;
        position: absolute;
      }
      &:before {
        background: #F2F2F2;
      }
      &:after {
        background: #fff;
        transform: scale(0);
      }
    }
    &:checked + span {
      &:before {
        transform: scale(1.0);
        background: #EA1D2C;
      }
      &:after {
        transform: scale(0.5);
        transition: transform 0.3s ease;
      }
    }
  }
}
