.modal-alter-status {
    &__form {
        &--in-separation {
            .react-datepicker__input-container .form-brand__input {
                &--start-date {
                    width: 250px;
                }
            }
        }
    }
}