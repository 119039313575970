@import "../../../assets/styles/variables";

.dashboard {
  &__title {
    display: flex;
    margin: 32px 0px 8px;
    align-items: baseline;
    font-size: $font-size-title;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.06px;

    .dashboard {
      &__help-icon {
        margin-left: 14px;
      }
    }

    &-titleSeller {
      font-size: $font-size-Xmedium;
      font-weight: 400;
    }

    &-sellerRequest {
      width: -webkit-fill-available;
    }

    &-right {
      color: $heavygrey;
      font-size: $font-size-small;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.06px;
      margin: 0px;
    }

    &-rightBold {
      color: $black;
      font-size: $font-size-Xmedium;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: -0.06px;
      margin: 0px;
    }

    &-paidValue {
      color: $ifood;
      font-size: $font-size-Xlarge;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: -0.06px;
      margin-top: 8px;
    }

    &-rightDiv {
      display: flex;
      flex-direction: column;
      padding: 24px;
    }

    &-rightDivBorder {
      border-left: solid 1px $regulargrey;
      padding: 24px;
    }

    &-small {
      margin-left: 10px;
    }
  }

  &__rightListOrder {
    color: $ifood;
    text-decoration: underline;
    width: max-content;
    font-size: $font-size-medium;
  }

  &__button {
    width: 100%;
    cursor: pointer;
  }

  &__card {
    width: 100% !important;
    border: 1px solid $regulargrey;
  }

  &__grid-item {
    font-size: $font-size-medium;
  }

  &__grid-request {
    background-color: $greenlight;
    border-radius: 6px;
    padding: 24px;
    width: -webkit-fill-available;
  }

  &__grid-item-border-left {
    padding: 24px;
  }

  &__receivable {
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
    border: 1px solid $regulargrey;
    border-radius: 6px;
  }

  .MuiGrid-item {
    display: flex;
    justify-content: space-between;
  }

  .Highlight-title-142 {
    font-size: $font-size-medium;
    font-weight: 500;
  }

  .MuiTypography-h4 {
    font-size: $font-size-title;
  }

  .MuiTypography-h5 {
    font-size: $font-size-large;
  }

  .react-datepicker {
    width: 242px;
  }

  .dashboardCardInformation {
    margin: 18px;
  }

}

