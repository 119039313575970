@import "../../../assets/styles/style.scss";

.edit-product {

  .product-variations {
    margin-top: 56px;
    padding-top: 56px;
    border-top: 4px solid $lightgrey;  
  }

  .table-admin {
    &--sample {
      margin-bottom: 24px;
      margin-top: 24px;

      thead {
        .table-admin {
          &__title {
            &:first-child {
              min-width: 70px;
            }
            &:nth-child(2),
            &:nth-child(4),
            &:nth-child(5),
            &:nth-child(6){
              min-width: 150px;
            }
          }
        }
      }
    }
  }
}
