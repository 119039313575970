.suggestion-register {
  .header-product {
    margin-bottom: 40px;
  }

  .options {
    margin-top: 56px;
    padding-top: 56px;

    .btn-secondary {
      margin-left: 16px;
    }
  }

  .button-suggestion {
    max-width: 1080px;
    display: flex;
    justify-content: flex-end;
    position: relative;

    .button,
    .btn-secondary {
      display: block;
      align-items: center;
      width: 168px;
      height: 48px;
      font-size: 18px;
      margin-left: 16px;
      text-align: center;
      border-radius: 5px;
    }
  }

  .modal-wrapper {
    .modal {
      width: 400px;
      overflow-y: inherit;

      &__title {
        padding: 16px 0 0 0;
      }

      .dropdown-ifood {
        margin-bottom: 24px;
      }
    }
  }
}
