@import '../../assets/styles/variables';

.checkbox-ifood {
  position: relative;
  max-width: 16px;
  max-height: 16px;

  &__input {
    width: 16px;
    height: 16px;
    opacity: 0;
    cursor: pointer;

    border: 1px solid #DCDCDC;
    background: $white;

    &:checked {
      background: $ifood;
      + .checkbox-ifood {
        &__mask {
          background: url("../../assets/icons/checked.svg");
          background-size: contain;
        }
      }
    }
  }

  &__mask {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px ;
    height: 16px;
    border: 1px solid #DCDCDC;
    border-radius: 4px;
    background-size: contain;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, calc(-50% - 1px));
    z-index: -1;
  }

  &__label {
    padding: 4px 0 0 8px;
    color: $heavygrey;
    cursor: pointer;
  }

  &:hover {
    cursor: pointer;
  }
}

input {
  margin: 0 !important;
}