.eyehide {
  max-width: 20px;
  cursor: pointer;
  z-index: 10;
  position: relative;
  left: 360px;
  bottom: 28px;
}

.eyeshow {
  max-width: 20px;
  cursor: pointer;
  z-index: 10;
  position: relative;
  left: 360px;
  bottom: 26px;
}