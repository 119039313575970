.containerReason{
  width: 100%;
  max-width: 700px;
  border-radius: 6px;
  padding-left: 25px;

  &__title {
    width: 100%;
    height: 24px;
    text-align: left;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #3e3e3e;
    font-family: 'SulSans', Helvetica, sans-serif;
    margin-bottom: 25px;
    margin-left: -8px;
  }

  &__reasons {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #717171;
    font-family: 'SulSans', Helvetica, sans-serif;
    padding: 0 0 5px 10px;
  }

  &__content {
    max-height: 612px;
    overflow-x: auto;
    width: 100%;
  }

 

  &__containerRadios {
    display: flex;
    text-align: left;
    justify-items: left;
    justify-content: flex-start;
  }

  &__radios {
    display: flex;
    text-align: left;
    height: auto;
    line-height: 24px;
    padding-top: 15px;

  }

  &__subReasons {
    padding-left: 28px;
    line-height: 24px;
    display: flex;
    padding-top: 4px;

  }

  &__radios input{
    border: 1px solid #444 !important;
  }

  &__modalbtns {
    text-align: right;
    justify-content: flex-end;
  }

  &__modalbtns button{
    margin-left: 16px;
  }

  &__textarea{
    margin-bottom: 42px;
    margin-top: 12px;
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    color: #717171;
    font-family: 'SulSans', Helvetica, sans-serif;
  }

  .centerButton {
    text-align: center;
  }

  .buttonOrderReason{
    font-size: 16px;
    min-width: 100px;
    justify-content: center;
  }

  // override default css
 .radio {
    display: block;
    cursor: pointer;
    border: solid 1px #dcdcdc;
    border-radius: 50%;
  }

  .outlined-label-wrap {
    width: 100%;
  }

  .buttonOrderReason{
    &.btn-secondary {
      padding: 0 24px;
    }
  }

}