.edit-specification {

  .header-category {
    margin-bottom: 32px;
    margin-top: 0px;

    &__info {
      margin-bottom: 0;
    }

    &__description {
      margin-top: 0px;
    }
  }

  .btn--white{
    margin-left: 16px;
  }

  .table-customer{
    margin-bottom: 24px;
  }  
}