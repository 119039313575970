.wrapper-reason{
  .typography{
    margin: 0 0 20px;
  }
  .radio-input{
    padding-right:8px;
  }
  .outlined-label-wrap{
    width: 100%;
    margin-right: 0;
  }
}
.list-reason {
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
  box-sizing: border-box;
  &__item {
      display: flex;
      width: 100%;
      align-items: center;
      margin-bottom: 24px;
      label:not(.radio){
        padding-top:2px;
      }
  }
}
