.modal-update-import-notice {
    max-width: 500px;

    &__subtitle {
        padding: 8px 0;

        &:last-child {            
            padding: 16px 0;
        }
    }

    &__button-import {
        float: right;
        margin-top: 24px;
    }

    .link.modal-update-import-notice__link {
        border: 0;
        padding: 0;
    }
}