@import "./assets/styles/style.scss";
@import "~ifoodshop-react-ui/dist/index.css";

.main {
  display: flex;
}

.section-title {
  margin-top: 0;
  margin-bottom: 8px;
  color: $black;
  font-size: $font-size-title;
  font-weight: 500;

  &--discount-form {
    margin: 56px 0 24px;
  }
}

.form-subtitle {
  font-size: $font-size-title;
  color: $heavygrey;
  margin: 30px 0 0;
}

.content-body {
  width: 100%;
  padding: 48px 32px 32px 64px;
  margin-left: 180px;

  .container {
    width: 643px;
  }

  .modal-wrapper {
    .modal-vehicle {
      padding: 8px 24px 24px 24px;
      .modal {
        &__body {
          margin-top: 12px;
        }
        &__close {
          margin-top: 4px;
          margin-right: 18px;
        }
      }
    }
  }

  &__box {
    display: flex;
    flex-flow: wrap row;
  }

  .text-small {
    font-size: $font-size-small;
  }

  .align-items-bottom {
    align-items: flex-end;
  }

  .togglebutton-ifood {
    border-radius: 6px;

    &__item {
      height: auto;
      padding: 9px 9px 6px;
      font-family: $font-family;
      font-size: $font-size-medium;
      font-weight: normal;
      text-transform: none;
      line-height: 16px;
      color: $mediumgrey;
      border: 1px solid $regulargrey;
      border-radius: 6px;
      background: $offwhite;

      &:not(:first-child) {
        .MuiTouchRipple-root {
          border-left: 1px solid $regulargrey;
        }
      }
      &.Mui-selected {
        background: $ifood;
        color: white;

        &:hover {
          background: $ifood;
        }
      }
    }
  }

  .MuiToggleButtonGroup-grouped:not(:first-child) {
    border-left: none;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 20px) scale(1);
    font-family: $font-family;

    &.MuiInputLabel-shrink {
      transform: translate(14px, -8px) scale(1);
      font-size: $font-size-large;
      background: white;
      padding: 0 4px;
    }
  }

  .react-datepicker-popper {
    z-index: 999;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
  }

  .MuiTab-root,
  .MuiTypography-root {
    font-family: $font-family;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    background: white;
    font-size: $font-size-large;
    color: $mediumgrey;
  }
}

.modal-wrapper {
  right: 0;

  > .modal {
    > .modal {
      &__title {
        width: 100%;
        text-align: center;
        font-size: $font-size-title;
      }
    }
  }
}

.btn {
  max-height: 40px;

  &-secondary {
    padding: 16px;
    font-size: $font-size-medium;
    font-weight: 500;
    color: $ifood;
    background: white;
    border: 1px solid $ifood;
    cursor: pointer;
    text-decoration: none;
  }

  &--white {
    background: $white;
    color: $ifood;
    padding: 10px 12px;
    text-align: center;
    border: 1px solid $ifood;
    border-radius: 4px;
    font-size: 18px;
  }
}

.link {
  cursor: pointer;
  &[disabled]{
    pointer-events: none;
    cursor: default;
    color: $mediumgrey;
    svg,
    path{
      fill: $mediumgrey
    }
  }
}

.go-back {
  position: absolute;
  top: 48px;
  left: 210px;
  background: url("./assets/icons/icon-goback.svg") no-repeat;
  width: 10px;
  height: 40px;

  &--btn {
    border: none;
    cursor: pointer;
  }
}

.outlined-label {
  z-index: auto;

  &-field {
    z-index: 9;

    &:not(:placeholder-shown) {
      &.outlined-label {
        &-field {
          &--error {
            background: $bubblegum;
            border: 0.5px solid $red-light;
          }
        }
      }
    }
  }
}

.outlined-label-field:focus + .outlined-label,
.outlined-label-field:not(:placeholder-shown) + .outlined-label {
  z-index: 10;
}

.list-sample {
  list-style: none;
  padding: 0 16px;

  li {
    margin-bottom: 16px;
  }
}

.outlined-label-wrap {
  margin-right: 16px;

  &.outlined-date {
    margin-right: 0;

    .form-brand__input {
      background: transparent;
    }
    .outlined-label {
      top: 50%;
      padding: 0.75em;
      width: auto;
      color: $mediumgrey;
      // background: white;
      transform: translate(0, -50%);
      transition: transform 0.25s, opacity 0.25s, padding 0.25s ease-in-out;
      transform-origin: 0 0;
      z-index: -1;
    }
    .react-datepicker__tab-loop + .outlined-label {
      padding: 0 5px;
      margin-left: 11px;
      top: 1.5em;
      transform: translate(0, -2em) scale(0.9);
      background-color: white;
      z-index: 10;
    }

    &.active {
      .outlined-label {
        padding: 0 5px;
        margin-left: 11px;
        top: 1.5em;
        transform: translate(0, -2em) scale(0.9);
        background-color: white;
        z-index: 10;
      }
    }
  }
}

.react-datepicker {
  font-family: $font-family;
  color: $black;
  width: 330px;

  &__header {
    background-color: white !important;
  }

  &__day--selected,
  &__day--keyboard-selected {
    border-radius: 50% !important;
    background-color: #ea1d2c !important;
  }

  &__input-container {
    .form-brand {
      &__input {
        width: 233px;
      }
    }
  }
}

.MuiOutlinedInput-root {
  .MuiOutlinedInput-notchedOutline {
    border: 1px solid $regulargrey !important;
  }

  &:hover {
    border-color: $regulargrey !important;
  }
}

.MuiFormLabel-root.Mui-focused {
  color: $mediumgrey !important;
  font-size: $font-size-title;
  background: white;
  padding: 2px 5px;
}

.description-form {
  margin: 0 0 40px 0;
}

.form-admin {
  &__group {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 24px;

    &--author {
      justify-content: space-between;
    }

    .outlined-label-wrap {
      height: 40px;
    }
  }

  &__input-percent {
    width: 120px;
  }
}

.author {
  position: fixed;
  right: 8px;
  bottom: 8px;
  color: $heavygrey;
  font-size: $font-size-small;
}

.react-toggle {
  &:hover:not(.react-toggle--disabled) {
    .react-toggle-track {
      background-color: $regulargrey;
    }
  }

  &-track {
    height: 16px;
    background: $regulargrey;

    &-check,
    &-x {
      display: none;
    }
  }

  &-thumb {
    top: -5px;
    width: 24px;
    height: 24px;
    background: $lightgrey;
    border-color: $lightgrey;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
  }

  &--checked {
    .react-toggle {
      &-track {
        background: $red-light;
      }

      &-thumb {
        border-color: $ifood;
        background: $ifood;
      }
    }

    &:hover:not(.react-toggle--disabled) {
      .react-toggle-track {
        background: $red-light;
      }
    }
  }

  &--focus {
    .react-toggle-thumb {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
    }
  }

  &:active:not(.react-toggle--disabled) {
    .react-toggle-thumb {
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
    }
  }

  &:hover {
    .react-toggle {
      &-track {
        background: $regulargrey;
      }
    }
  }
}

.link {
  &:hover {
    color: $red-dark;
    cursor: pointer;
  }

  &--disabled {
    color: $mediumgrey;
    cursor: default;

    &:hover {
      color: $mediumgrey;
      cursor: default;
    }
  }
}

.MuiTooltip-popper {
  font-family: $font-family;

  .MuiTooltip-tooltip {
    margin: 8px 0;
    font-family: $font-family;
    font-size: 12px;
    font-weight: normal;
    color: white;
    background: $heavygrey;
    text-shadow: none;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.24);
  }
}

.outlined-label-field:not(textarea),
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  min-height: auto;
  height: 40px;
}

.MuiFormControl-root {
  &.disabled {
    background: $lightgrey;
  }
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
  font-family: $font-family;
  color: $mediumgrey;
  font-size: $font-size-large;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiAutocomplete-input.MuiAutocomplete-inputFocused.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
  padding: 0px 4px;
  font-family: $font-family;
  font-size: $font-size-large;
}

.button {
  font-size: $font-size-medium;
  &-lg {
    padding-left: 24px;
    padding-right: 24px;
    font-size: $font-size-large;
  }
}

.table-box {
  display: inline-block;
  width: 100%;
}

.MuiAutocomplete-popper {
  min-width: 400px;
}

.MuiAutocomplete-option,
.MuiInputLabel-outlined {
  font-family: $font-family;
  font-size: $font-size-large;
}

.MuiInputBase-root {
  &.Mui-disabled {
    background: $offwhite;
  }
}

.input[disabled] {
  z-index: 0;
}

.toggle-wrap {
  &__label {
    margin-right: 12px;
  }
}

.label-error {
  font-size: $font-size-small;
  color: $ifood;
  margin: 0;
  padding: 4px;
}

.colored {
  color: $ifood;
}

.MuiGrid-root {
  .outlined-label-wrap {
    margin-right: 0;
  }
}

.form-brand__search,
.form-brand__suggest {
  .dropdown-label-wrap {
    label[data-shrink="false"] {
      top: -7px;
    }

    .Mui-focused {
      top: 0px;
    }
  }
}

.options-link {
  .dropdown-ifood {
    a {
      width: 100%;
      text-decoration: none;
      color: #717171;
      background-color: #fff;

      &:hover {
        text-decoration: none;
        color: #717171;
        background-color: #f2f2f2;
      }
    }

    label {
      width: 100%;
      text-decoration: none;
      color: #717171;
      background-color: #fff;

      &:hover {
        text-decoration: none;
        color: #717171;
        background-color: #f2f2f2;
      }
    }
  }
}

.menu-myData {
  .MuiTabs-root {
    min-height: 0px;
    max-width: max-content;
  }
  .MuiTab-root {
    margin-left: 32px;
    padding: 0px;
    min-width: 0px;
    min-height: 0px;

    &:first-child {
      margin-left: 0px;
    }
  }

  .MuiTab-wrapper {
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: right;
    color: #717171;
  }

  .Mui-selected {
    .MuiTab-wrapper {
      color: #ea1d2c;
      font-weight: 500;
    }
  }

  .MuiTabs-flexContainer {
    border-bottom: 3px solid #dcdcdc;
  }

  .PrivateTabIndicator-colorSecondary-3 {
    background-color: #ea1d2c;
  }
}
