@import '../../../assets/styles/style.scss';

.categories {
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 32px;
  }
}

.categoryInputsFilter {
  display: flex;
  align-items: center;
  margin-bottom: 50px;

  .inputCategoryDiv {
    margin-bottom: 0px;
    margin-top: 0px;
    width: 300px;
  }
}

#dropDownCategory {
  margin-left: 35px;
  width: 150px;

  .dropdown-ifood__control {
    min-height: 48px;
    padding: 15px 35px 8px 15px;
  }
}

#buttonNewCategory {
    border: 0;
    font-weight: 700;
    text-decoration: none;
    border-radius: 6px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 16px;
    color: #FFFFFF;
    background-color: #EA1D2C;
    font-family: iFood RC Textos,sans-serif
}

.categoryList {
  .categoryTitleHeader {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    font-weight: 500;
    font-family: iFood RC Textos, sans-serif;
    
    #categoryIdP {
      position: relative;
      right: 132px;
    }

    #categoryIdent {
      position: relative;
      right: 404px;
    }

    #categoryStatus {
      position: relative;
      right: 404px;
    }
  }
}

.idCategoryTree {
  font-size: 14px; 
}

.tree {
  .MuiTreeItem-label {
    font-family: iFood RC Titulos, sans-serif;
    font-size: $font-size-medium;
    width: auto;

    &:hover {
      background: transparent !important;
    }
  }

  .MuiTreeItem-group {
    margin-left: 0;

    .MuiTreeItem-group {
      .tree {
        &__name {
          padding-left: 80px;

          &:after {
            display: none;
          }
        }
      }
    }

    .tree {
      &__name {
        padding-left: 48px;
      }
    }
  }

  .MuiTreeItem-content {
    position: relative;
  }

  .MuiTreeItem-iconContainer {
    position: absolute;
    left: 432px;
    z-index: 9999;
  }

  .MuiTreeItem-root {
    &.Mui-selected {
      .MuiTreeItem-content {
        .MuiTreeItem-label {
          background: transparent;
        }
      }
    }

    &:focus {
      >.MuiTreeItem-content {
        .MuiTreeItem-label {
          background: transparent !important;
        }
      }
    }
  }

  .Mui-expanded {
    >.MuiTreeItem-content {
      .tree {
        &__name {
          color: $ifood;
        }
      }
    }
  }

  &__item {
    display: flex;
    align-items: stretch;
  }

  &__name {
    position: relative;
    width: 450px;
    padding: 8px 16px;
    font-size: $font-size-medium;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }

  &__status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 136px;

    &-label {
      padding: 4px 16px;
      font-size: $font-size-small;
      font-weight: bold;
      border-radius: 17px;
      line-height: 16px;

      &.enabled {
        color:  #50A773;;
        background: #fff;
        border: 2px solid #50A773;
      }

      &.disabled {
        color: #A6A6A5;
        background: #fff;
        border: 2px solid #DCDCDC;
      }
    }
  }

  &__edit {
    padding: 13px 40px;
    line-height: 16px;
  }
}