@import '../../assets/styles/style.scss';

.label-snackbar {
  display: inline-flex;
  padding: 8px 16px;
  font-size: $font-size-large;
  line-height: 24px;
  color: white;
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.24);
  animation: animationFrames linear 3s;
  animation-iteration-count: 1;

  &--success {
    background: $avocado;
  }
  &__icon-box {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    margin-top: 3px;
    margin-right: 9px;
    border-radius: 14px;
    background: white;

  }
  &__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 9px;
    height: 9px;
    transform: translate(-50%, -60%);
  }
}

@keyframes animationFrames{
  0% {
    transform:  rotate(0deg) scaleX(0.8) scaleY(0.8) ;
  }
  10% {
    transform:  rotate(0deg) scaleX(1.05) scaleY(1.05) ;
  }
  20% {
    transform:  rotate(0deg) scaleX(1) scaleY(1) ;
  }
  90% {
    transform:  rotate(0deg) scaleX(1) scaleY(1) ;
  }
  92% {
    transform:  rotate(0deg) scaleX(0.9) scaleY(0.9) ;
  }
  100% {
    transform:  rotate(0deg) scaleX(0) scaleY(0) ;
  }
}