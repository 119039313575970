@import "../../../../assets/styles/variables";

.form-register {
  &__prdvariation {
    .input {
      margin-bottom: 24px;
      width: 200px;
    }

    .dropdown-label-wrap {
      position: relative;
      width: 200px;
      margin-bottom: 24px;
    }

    .dimensions {
      padding-top: 16px;

      .outlined-label-wrap {
        display: inline-block;

        .input {
          min-width: 160px;
        }
      }

      .outlined-label-field {
        + .outlined-label {
          margin-left: 24px;
          top: 35%;
        }
      }
    }

    .product-specifications{
      margin-top: 56px;
    }
  }
}
