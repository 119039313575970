@import "../../../../assets/styles/style.scss";

.form-brand {
  &--product {
    width: 100%;

    .header-customer-group__actions-more{
      position: relative;
    }

    .product{
      .outlined-label-wrap{          
          margin-right: 0px;       
      }
    }

    .form-row {
      justify-content: space-between;
    }

    .form-brand {
      &__search {
        display: flex;
      }
      
      
    }

    .options-link {
      position: relative;

      .modal__body {
        .options-btn {
          display: flex;
          flex-direction: column;

          .link {
            padding: 0px 0px;
          }
        }
      }
    }

    .dropdown-label-wrap {
      margin-left: 16px;
      margin-right: 0;

      .dropdown-ifood__more-actions{
        width: 240px;
      }

      .MuiAutocomplete-endAdornment {
        .MuiIconButton-root{
          transition: all 0.3s;
        }
        .dropdown-ifood__arrow {
          transform: translateY(0);
          transition: all 0.3s;
        }
      }
    }
  }
}

.countImages {
  margin-bottom: 10px;
}

.search-product {
  .MuiInputLabel-outlined {
    transform: translate(12px, 20px) scale(1);
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 6.5px 4px 6.5px 4px;
  }

  .product-images{
    display: flex;
    flex-direction: column;
    align-items: center;
    &__list{
      padding: 0;
      text-align: center;
      &__label{
        margin-bottom: 10px;
      }
      &--item{
        line-height: 20px;
        list-style: none;        
      }
    }
  }
}