.suggestion-register {
  .header-product {
    margin-bottom: 40px;
  }
  .button-suggestion {
    max-width: 1080px;
    display: flex;
    justify-content: flex-end;
    position: relative;

    .button,
    .btn-secondary {
      display: block;
      align-items: center;
      width: 168px;
      height: 48px;
      font-size: 18px;
      margin-left: 16px;
      text-align: center;
      border-radius: 5px;
    }
  }
}
