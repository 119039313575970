@import "../../../../assets//styles/variables";

.form-register {
  font-family: $font-family;

  &--product {
    font-family: $font-family;

    .toggle-wrap {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      &__label {
        margin-right: 16px;
      }
    }

    label[for="productNameFormRegister"] {
      top: 20px;
    }

    .list-fields {
      margin-bottom: 16px;
      width: 200px;

      .outlined-label-wrap {
        margin-right: 0;
      }

      .MuiInputBase-input {
        font-family: $font-family;
      }

      .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
        font-family: $font-family;
        color: $mediumgrey;
      }
    }

    .input {
      margin-bottom: 24px;
      width: 400px;
    }

    .dropdown-label-wrap {
      width: 200px;
      margin-bottom: 24px;

      .MuiInputBase-root {
        font-family: $font-family;
      }

      .PrivateNotchedOutline-root-103.MuiOutlinedInput-notchedOutline {
        border: 1px solid #dcdcdc;
      }

      .MuiFormLabel-root.Mui-focused {
        color: $heavygrey;
      }

      &__category {
        width: 400px;
        margin-bottom: 24px;
      }
    }

    .autocomplete-brand {
      display: flex;
      margin-bottom: 24px;
      align-items: center;

      .MuiInputBase-root {
        width: 240px;
        margin-right: 16px;
      }
    }
  }
}
