@import "../../../../../assets/styles/style.scss";

.mainData {
  width: 440px;

 &__attribute{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $heavygrey;
  }

  &__value {
    line-height: 24px;
    font-weight: 500;
    color: $black;
  }

  &__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 24px;
    margin-top: 32px;
  }

  &__name, &__tel, &__cel {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__link {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }

  &::after {
    content: '';
    display: block;
    width: 440px;
    height: 1px;
    background-color: $regulargrey;
    margin-top: 32px;
  }
}


