.edit-category {
  display: flex;
  flex-direction: column;
  padding-bottom: 48px;

  .form-edit{
    &--category{
      display: flex;

      .dropdown-label-wrap {
        margin-left: 16px;
        &__category{
          margin-left: 8px;
          margin-bottom: 24px;
          width: 400px;
        }
      } 
      .outlined-label-wrap{
        margin-right: 0;
        width: 240px;
        height: 40px;       
      }     
    }
  }

  
  .header-category {
    margin-bottom: 0px;
    margin-top: 0px;

    &__info {
      margin-bottom: 40px;
    }

    &__description {
      margin-top: 0px;
    }
  }

  .btn--white{
    margin-left: 16px;
    text-decoration: none;
  }

  .table-admin{
    &--sample {
      margin-bottom: 24px;
      width: fit-content;
      min-width: 300px;

      td {
        padding: 8px 16px;
      }
    }
  }

  h3 {
    font-weight: 300;
    margin-bottom: 24px;
  }

  &__action-space {
    margin-top: 24px;
  }
}
