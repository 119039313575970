@import "@assets/styles/variables";

.sellerContracts {
  width: 440px;

  &__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 300;
    margin: 32px 0 24px 0;
    color: $black;
  }

  &__contract-type {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  &__attribute {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $heavygrey;
  }

  &__value {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: $black;
  }

  &__contract-options {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .edit-contract {
      margin-bottom: 28px;
    }

    .see-contract {
        display: flex;
        align-items: center;
      &:before {
        content: "";
        display: inline-block;
        background-image: url("../../../../../assets/icons/contract.svg");
        background-repeat: no-repeat;
        width: 24px;
        height: 24px;
        padding: 0 0 2px 0;
      }
    }
  }

}
