#link-copy-info {
  position: fixed;
  padding: 0;
  margin: 0;
  top: calc(100% - 70px);
  left: 20px;
  z-index: 2;
}

#pwLinkTemporary {
  cursor: pointer;
}