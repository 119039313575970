@import "../../assets/styles/variables";

.html-editor {

  margin-bottom: 24px;
  .file-editor {
    &__input {
      display: none;
    }
  }
  
}
