@import '../../../../assets/styles/variables';

.form-register {

  &--category {  
 
  .outlined-label-wrap {   
    margin-bottom: 24px;
    .input{
      width: 400px;
      margin: 0px;
    }     
  }  
  
  .dropdown-label-wrap {
    width: 200px;
    margin-bottom: 24px;
    &__category{
      width: 400px;
      margin-bottom: 24px;
    }
  }  
 }
}