@import '../../../../assets/styles/variables';

.header-form {
  &--carrier {
    .header-form {
      &__nav {
        display: flex;
        justify-content: space-between;
        padding-right: 32px;
        border-bottom: 1px solid $regulargrey;
        
        &-item {
          text-decoration: none;
          padding-left: 8px;
          font-size: $font-size-title;
          line-height: 24px;
          color: $mediumgrey;
          
          &.active,
          &:hover {
            &:not([disabled]){
              cursor: pointer;
              color: $ifood;
              border-bottom: 2px solid $ifood;
            }
          }

          &[disabled] {
            cursor: default;
          }
        }
      }
    }
  }
}