.prod-variation {
  &__success {
    position: fixed;
    bottom: 30px;
  }

  .toggle-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &__label {
      margin-right: 16px;
    }
  }
}
