.header-collection {
  .togglebutton-ifood {
    flex: 100%;
    margin-bottom: 16px;
  }
  .autocomplete-ifood {
    width: 200px;
  }
  .header-customer-group {
    &__new {
      position: relative;
    }
    &__actions {
      &-link {
        &.link-see-more {
          margin-right: 14px;
        }
      }
      &-more {
        left: 0;
        z-index: 10;
      }
    }
  }
}

.width-100 {
  width: 100%;
}

.min-width-25em {
  min-width: 25em;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}