.form-register {
  &--offer {
    .input {
      margin-bottom: 24px;
      width: 465px !important;
      height: 42px;
    }

    .outlined-label-wrap {
      width: 500px;
    }

    .dropdown-label-wrap {
      width: 465px !important;
      margin-bottom: 24px;
      
      .Dropdown-placeholder {
        color: #717171;
      }
    }

    .go-back {
      left: 270px;
    }

    .stock {
      .outlined-label {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 465px !important;
        margin: 0px 0px 24px 0px;
        height: 40px;
        margin-bottom: 24px;
      }
    }
    
    .headerFormEdit {
      display: flex;
      justify-content: space-between;
      margin-bottom: 28px;

      .btn-save {
        border-radius: 6px;
        color: #fff;
        font-family: iFood RC Titulos, sans-serif;
        font-size: 16px;
        font-weight: 700;
        height: 48px;
      }
    }

    .contentFormEdit {
      border-top: 1px #DCDCDC solid;
    }

    .radioButtonsFormGroup {
      display: flex;
      margin-bottom: 24px;
      margin-top: 10px;

      .radioButtonsEditOffer {
        max-width: 18px;
        margin-top: 2px; 
        margin-right: 8px;

        .radio-input {
            &:first-child {
              margin-bottom: 10px;
            }
        }
      }

      .radioReasons {
        display: flex;
        flex-direction: column;
        font-family: iFood RC Textos, sans-serif;

      .labelRadioReasons {
        &:first-child {
          margin-bottom: 8px;     
        }
      }
      }
    }

    .special-prices {
      &__title {
        margin-top: 48px;
        margin-bottom: 35px;
      }

      &__content {
        &__row {
          display: flex;
          gap: 14px;

          .dropdown-label-wrap,
          .dropdown-ifood__control {
            margin-bottom: 0px;
            height: 42px;
          }
        }

        &--description {
          margin-bottom: 16px;
        }
      }
    }

    .labelOfferEdit {  
      margin-bottom: 4px;
      margin-top: 0;
      font-family: iFood RC Textos, sans-serif;
      font-size: 16px;
      color: #3E3E3E;
    }

    #skuLabelEdit {
      margin-top: 32px;
    }

    .labelOfferInput {
      color: #717171;
      font-family: iFood RC Textos, sans-serif;
      font-size: 16px;
    }

     .react-toggle {
      cursor: pointer;
      &:hover:not(.react-toggle--disabled) {
        .react-toggle-track {
          background-color: #DCDCDC;
        }
      }

      &-track {
        background: #DCDCDC;

        &-check,
        &-x {
          display: none;
        }
      }

      &-thumb {
        background: #F2F2F2;
        border-color: #F2F2F2;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
      }

      &--checked {
        cursor: pointer;
        .react-toggle {
          &-track {
            background: #EA1D2C;
          }

          &-thumb {
            border-color: #fff;
            background: #fff;
          }
        }

        &:hover:not(.react-toggle--disabled) {
          .react-toggle-track {
            background: #EF5753;
          }
        }
      }

      &--focus {
        .react-toggle-thumb {
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
        }
      }

      &:active:not(.react-toggle--disabled) {
        .react-toggle-thumb {
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
        }
      }

      &:hover {
        .react-toggle {
          &-track {
            background: #DCDCDC;
          }
        }
      }
    }

  .toggle-wrap {
    margin-bottom: 24px;

    .toggle-wrap__label {
      margin-bottom: 2px;
    }
  }

  .spanAlertPriceKg {
    position: relative;
    top: -21px;
    color: #3E3E3E;
    font-family: iFood RC Textos, sans-serif;
    font-size: 12px;
    margin-left: 6px;
  }

  }  
}
