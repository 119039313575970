.modal-alter-status {
    width: 35em;
    min-height: 20em;
    max-height: 40em !important;
    overflow: inherit !important;
    
    &__title {
        text-align: center;
        margin-bottom: 32px;
    }

    &__form {
        > * {
            margin: 1em;
        }

        &__error {
            margin-left: 1em;
            color: #EA1D2C;
        }
    }

    &__submit {
        float: right;
    }

    &__error-date{
        color: #EA1D2C!important;
    }
}

.alter-status-arrow {
    margin-left: 1em;
    margin-right: 1em;
}

.modal-alter-status-reason{
    width: 35em;
}
