@import '../../assets/styles/variables';

.scroll {
  text-align: left;
  height: 150px;
  width: 600px;
  margin-bottom: 40px;
  margin-top: 0px;
  padding-bottom: 10px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.24);
  .item{
    padding: 4px;
    height: 50px;
  }
}
