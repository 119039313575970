@import '../../assets/styles/variables.scss';

.table-brand {
  border-spacing: 0;
  width: 100%;

  th,
  td {
    text-align: left;
    padding: 8px 16px;
    border-right: 1px solid $mediumgrey;
    color: $black;
  }
  &__edit-link{
    color: $ifood;

    &:hover{
      color: $ifood;
    }
  }

  thead {
    th {
      font-weight: 500;
      font-size: $font-size-large;

      &:first-child {
        width: 30px;
        border-right: 0;
      }
      &:nth-child(2) {
        width: 70px;;
      }
      &:last-child {
        width: 50%;
        border-right: 0px;
      }
    }
  }
  tbody {
    tr {
      td {
        font-size: $font-size-medium;

        &:first-child {
          padding: 0 9px 0 0;
          border-right: 0;
        }
        &:last-child {
          border-right: 0px;
        }
      }
      &:nth-child(odd) {
        td:not(:first-child) {
          background: $offwhite;
        }
      }
    }
  }
}