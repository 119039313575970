@import '../../../../assets/styles/variables';

.form-register {

  &--category {

  .input {
    margin-bottom: 24px;
    width: 200px;
  }

  .dropdown-label-wrap {
    width: 200px;
    margin-bottom: 24px;
  }


 }
}