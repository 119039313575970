.form-commercialcondition {
  .form-register {
    &__input {
      max-width: 200px !important;
    }

    label[for="name"] {
      top: 20px;
    }

    &__button {
      margin-left: 16px;
    }

    &__description {
      margin-bottom: 24px;
    }

    &__flex {
      display: flex;
      &--autocomplete {
        margin-left: 16px;
      }
    }

    &__toggle-button {
      margin: 2px 0 0 16px;
    }

    &__button-save {
      margin-top: 32px;
    }
  }
}
