.header {
  width: 1080px;

  &--step {
    display: inline-flex;
    flex-direction: row-reverse;
    padding-bottom: 16px;
    padding-top: 16px;
  }

  &__itens {
    display: flex;
    justify-content: space-between;
  }
  &__step {
    display: inline-flex;
    width: 330px;
    height: 8px;
    border-radius: 24px;
    background-color: #dcdcdc;

    &--active {
      background-color: #ea1d2c;
    }
  }
}
