.pix-chargeback-success {
    width: 456px;
    max-width: 100%;
    display: grid;
    justify-items: center;
    gap: 16px;

    &__description {
        max-width: 330px;
        text-align: center;
    }

    &__button {
        margin-top: 20px;
        font-weight: bold;
    }
}