@import "../../../../../../assets/styles/variables";

.order-card-detais {
  width: 100%;
  display: flex;
  &__item {
    width: 100%;
    display: flex;
    padding-bottom: 33px;
    margin-bottom: 32px;
    border-bottom: 1px solid #dcdcdc;
  }
  &__figure {
    background-color: $lightgrey;
    border-radius: 12px;
    padding: 8px 12px;
    margin: 0 40px 0 0;
    display: flex;
    align-items: center;
    align-self: flex-start;
    min-width: 100px;
    min-height: 100px;
  }
  &__img {
    max-width: 76px;
    display: block;
  }
  &__description {
    p {
      margin: 0;
    }
    margin-top: 10px;
    .typography {
      display: block;
    }
  }
  &__title {
    margin: 0 0 8px;
    max-width: 80%;
    line-height: 24px;
  }
  &__columns {
    margin-bottom: 8px;
    display: flex;
    width: 100%;
    &__item {
      word-break: break-word;
      max-width: 50%;
      &:first-child {
        margin-right: 12px;
      }
    }
  }
}
