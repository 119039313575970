@import '../../../../assets/styles/variables';

.modal-content-confirm {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-around;
  height: auto;
  width: 443px;

  &__title{
    font-size: 20px;
    font-weight: 500;
    color: $black;
    align-self: flex-start;
    padding-left: 17px;
    margin-top: -25px;
  }

  &__description {
    font-size: 16px;
    line-height:24px;
    text-align: left;
    padding-left: 16px;
    color: $heavygrey;

    &-top {
      margin: 0;
    }
  }

  .modal-content{
    &.btn-align {
      align-items: flex-end;
      text-align: right;
    }
  }

   // override default css
  .btn-secondary {
    padding: 0 10px;
  }
  .counter-button{
    .button-loader{
      width: 67px;
    }
  }
}