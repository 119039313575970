.modal-content-footer {
  display: flex;
  width: 100%;
  &--space-primary{
    margin-top: 64px;
  }
  &--right {
    justify-content: flex-end;
  }
  &--center {
    justify-content:center;
  }
  .button {
    + .button{
      margin-left: 16px;
    }
  }
}