.cardGrey {
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  width: 494px;
  height: 112px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 999px) {
    padding: 16px 8px;
  }

  h2 {
    //styleName: Medium / 14|16;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  h3 {
    //styleName: Light / 14|16;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  .body-container {
    display: flex;
    flex-direction: row;
    .body-text-container {
      margin-left: 16px;
      width: 239px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
  .card-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
