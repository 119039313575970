.progressive-price {
  margin-bottom: 0px;

  .price-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .price-line {
    display: flex;
    margin-top: 24px;
  }
  .clicable-text {
    font-size: 14px;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: right;
    color: #ea1d2c;
    margin-right: 24px;
    cursor: pointer;
  }
  .second-dropdown {
    margin-top: 24px;
  }

  .outlined-label {
    font-size: 12px;
  }

  .image-container {
    width: 45px;
    height: 45px;
  }

  .price-title {
    margin-top: 0px;
  }

}
