.weight-form {
    &--error-input {
		border: 1px solid #EA1D2C!important;
		background: #FCEBEA!important;
	}
}

.order-detail{
	&__card-item{
		&--input{
			margin-bottom: 8px;
		}

		&--error-input{
			color: #EA1D2C!important;
		}

		&--reduced-space{
			display: flex;
			justify-content: space-between;
			margin: 0;
			margin-bottom: 8px;
		}

	}

	&__modal{
		max-width: 340px;
	}
	&__card-button{
		width: 78px;
	}
}

