@import '../../../assets//styles/style.scss';

.order-detail {
  
  &__link {
    color: #3e3e3e;
  }

  &__summary {
    display: flex;
    margin: 32px 0 40px;

    &-value {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 265px;

      &-item {
        display: flex;
        justify-content: space-between;
        margin: 0 0 16px;
      }
    }
    &-title {
      margin: 0;
      margin-right: 106px;
    }
  }
  &__payments-customer {
    display: flex;
    align-items: flex-start;
    margin: 40px 0 58px 0;
  }
  .cards-stretch {
    margin: 0 0 58px 0;
    justify-content: inherit;
    align-items: stretch;
    .flex-grid{
      &--wrap{
        margin: 0;
      }
    }
  }
  &__card {
    max-height: 660px;
    background: $offwhite;
    padding: 24px;
    border-radius: 4px;
    margin-right: 24px;
    overflow-y: auto;

    &--payments {
      margin-top: 24px;
    }

    &:last-child {
      margin-right: 0;
    }
    &-title {
      font-size: $font-size-title;
      font-weight: 500;
    }
    &-item {
      display: flex;
      justify-content: space-between;
      margin: 0;
      margin-bottom: 16px;

      &--strong {
        font-weight: 500;
        margin-bottom: 8px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      &--partner {
        color: #50A773;
      }
    }
    &-value {
      font-weight: 500;
    }
    &-list {
      padding-bottom: 24px;
      margin-bottom: 24px;
      border-bottom: 1px solid $heavygrey;

      &:last-child {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: 0;
      }
    }
  }
}
