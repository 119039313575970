@import "../../../../assets/styles/variables";

.product-images {
  display: flex;
  align-items: center;

  &__item {
    .image-list {
      display: flex;
      align-items: center;
      position: relative;
      text-align: center;
      width: 179px;
      height: 179px;
      border: 1px solid #dcdcdc;
      box-sizing: border-box;
      margin-left: 16px;
      padding: 4px;
    }
    .image-item {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin: 0;
    }
  }
}

.modal-import {
  width: 1080px;
  height: 179px;
  border: 1px dashed #ea1d2c;
  box-sizing: border-box;
  display: flex;

  .modal-import-itens {
    padding-top: 50px;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__zone-active {
    width: 179px;
    height: 179px;
    font-size: 14px;
  }

  .import-image {
    width: 82px;
  }
}
.register-suggestion {
  font-family: $font-family;
  .button {
    width: 168px;
    font-size: 18px;
    display: block;
  }

  .header-product__description {
    padding-bottom: 24px;

    &__title {
      padding-bottom: 8px;
    }
  }

  .outlined-label-wrap,
  .dropdown-ifood {
    margin-bottom: 24px;
    width: 340px;
  }

  .dropdown-label-wrap,
  .dropdown-ifood__control {
    height: 48px;
  }

  .Dropdown-placeholder,
  .dropdown-ifood__placeholder {
    margin-top: 4px;
  }

  .product-name {
    display: inline-flex;
    align-items: center;
    margin-bottom: 24px;
    margin-bottom: 24px;
    .outlined-label-wrap,
    .outlined-label-field {
      width: 1045px;
      margin-bottom: 0px;
      height: 48px;
    }
  }
  .unity_options,
  .input {
    width: 200px;
  }

  .dimensions {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 18px;
    max-width: 1080px;

    .outlined-label-wrap {
      display: flex;
      align-items: baseline;
      margin-right: 16px;
      width: 340px;
    }
    .outlined-label-field {
      width: 340px;
      height: 48px;
    }
  }

  .product-itens {
    display: flex;
    .outlined-label-field {
      width: 340px;
      height: 48px;
    }
  }
  .product-unity {
    display: flex;
    justify-content: space-between;
    max-width: 698px;
    padding-bottom: 40px;

    .outlined-label-wrap,
    .outlined-label-field,
    .dropdown-label-wrap {
      width: 340px;
      height: 48px;
      border-radius: 5px;
      margin: 0;
    }
    .dropdown-ifood__control {
      height: 48px;
    }
  }

  .product-inventory {
    padding-bottom: 18px;

    .register-suggestion,
    .unity_options,
    .register-suggestion,
    .input {
      width: 340px;
      height: 48px;
    }
  }

  .autocomplete {
    display: flex;
    flex-direction: column;
    .MuiAutocomplete-root {
      margin-bottom: 0px;
      height: 48px;
    }

    .dropdown-ifood {
      width: 400px;
    }

    .MuiInputBase-root {
      height: 48px;
    }
    .MuiFormLabel-root {
      top: 4px;
    }

    &__brand {
      display: flex;
      max-width: 696px;
      justify-content: space-between;
      .register-suggestion,
      .autocomplete,
      .dropdown-ifood {
        width: 340px;
      }
      margin-bottom: 24px;

      .button {
        max-height: 48px;
        margin-left: 16px;
      }
    }
  }
  .product-section {
    width: 1080px;
    border-top: solid 2px $lightgrey;
    padding-top: 40px;
  }

  .product-price {
    display: flex;
    padding-bottom: 16px;

    .outlined-label-wrap,
    .outlined-label-field,
    .dropdown-label-wrap {
      width: 340px;
      height: 48px;
      border-radius: 5px;
    }
    .dropdown-ifood__control {
      height: 48px;
    }
  }

  .suggestion-images {
    margin-top: 56px;
    border-top: 2px solid $lightgrey;
    padding-top: 56px;
  }

  .header-customer-group--specification {
    margin-top: 56px;
    padding-top: 56px;
    margin-bottom: 0;
    border-top: solid 2px $lightgrey;
  }
  .header-customer-group__info {
    margin-bottom: 0;
  }

  .product-specifications {
    display: grid;
    grid-column-gap: 27px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    max-width: 1080px;

    .header-product {
      visibility: hidden;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .product-Datasheet {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;

    .outlined-label-wrap,
    .outlined-label-field,
    .dropdown-label-wrap {
      width: 340px;
      height: 48px;
      border-radius: 5px;
    }
    .dropdown-ifood__control {
      height: 48px;
    }
    .dropdown-placeholder,
    .dropdown-ifood__placeholder {
      padding-top: 4px;
    }
  }

  .dropdown-label-wrap {
    width: 200px;
    margin-bottom: 24px;

    .MuiInputBase-input {
      font-family: $font-family;
    }

    .PrivateNotchedOutline-root-103.MuiOutlinedInput-notchedOutline {
      border: 1px solid #dcdcdc;
    }
    .MuiFormLabel-root.Mui-focused {
      color: $heavygrey;
    }
  }

  .list-fields {
    margin-bottom: 16px;
    width: 200px;

    .outlined-label-wrap {
      margin-right: 0px;
    }

    .MuiInputBase-input {
      font-family: $font-family;
    }
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
      font-family: $font-family;
      color: $mediumgrey;
    }
  }
}

.modal {
  &__suggestion {
    width: 300px;
    height: 300px;

    .form-register {
      margin-bottom: 0px;
    }
    .outlined-label-wrap {
      margin-right: 0px;
      margin-bottom: 24px;
      .input {
        width: 300px;
      }
    }
    .images {
      height: 300px;
      .outlined-label-wrap {
        margin-bottom: 24px;
      }
    }
  }
}
