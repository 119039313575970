.grid-list-container {
  width: 100%;
  height: 100%;
  padding: 4px;
}

.modalbtns {
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
}

.image-list {
  position: relative;
  text-align: center;
  width: 19%;
  margin: 0px 2px;

  .image-item {
    width: 100%;
    height: 150px;
  }
}

.btn-trash {
  position: absolute;
  bottom: 16px;
  right: 5px;
  padding: 15px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.24);
  text-decoration: none;
  z-index: 100;
  border-style: none;
  background-image: url("../../../../assets/icons/Trash.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: center;

  &__icon{
    width: 12px;
  }

  &:hover{
    cursor: pointer;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.24);

  }

  &:focus,
  &:active {
    outline: none;
  }

}
