@import '../../../../assets/styles/variables';

.form-edit {

  .btn-save {
    margin-bottom: 24px;
  }

  &--product {  
    margin-bottom: 80px;

  .input {   
    margin-bottom: 24px;
    width: 400px; 
  }  
  
  .dropdown-label-wrap {
    width: 200px;
    margin-bottom: 24px;
  }  
 }
}