@import '../../assets/styles/variables';

.nav-aside {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 183px;
  width: 183px;
  padding: 40px 16px 120px 32px;
  background: $offwhite;
  border-right: 1px solid $regulargrey;
  z-index: 1;

  &__brand {
    margin-bottom: 64px;
    padding-right: 16px;
    text-align: center;
  }

  &__nav {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
    margin-top: 0px;

    .nav-aside__nav-link,
    .nav-aside__submenu-link {
      display: block;
      text-decoration: none;

      &.active,
      &:hover {
        color: $ifood
      }
    }

    .nav-aside__submenu-link {
      font-size: $font-size-medium;
      color: $heavygrey;
      margin-bottom: 16px;
      line-height: 16px;
    }

    &-menu {
      margin-bottom: 24px;

      .nav-aside__nav-link {
        color: $black;
        margin-bottom: 16px;
        font-size: $font-size-large;
        line-height: 24px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__submenu {
    list-style: none;
    padding: 0 0 0 16px;
    margin: 0;
  }

  &__actions {
    position: fixed;
    left: 88px;
    bottom: 0;
    transform: translate(-50%, 0);
    background-color: #f7f7f7;
    border-right: 1px solid $regulargrey;
    min-width: 190px;
    padding: 16px 0;

    &-user {
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: '';
        display: inline-block;
        height: 17px;
        width: 16px;
        margin-right: 4px;
        background-image: url('../../assets/icons/User.svg');
        background-repeat: no-repeat;
      }
    }

    &-name {
      font-size: $font-size-medium;
      word-wrap: break-word;
      max-width: 130px;
      text-align: center;
      text-transform: capitalize;
    }
    .nav-aside__btn {
      display: block;
      margin-top: 24px;
      text-align: center;
      color: $ifood;
    }
  }
}
