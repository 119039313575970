.register-specification {
  .header-category {
    margin-bottom: 40px;

    &__info {
      margin-bottom: 0;
    }
    &__description {
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }
}
