@import '../../../../assets/styles/variables';

.form-register {

  &--specification {  
 
  .input {   
    margin-bottom: 24px;
    width: 200px; 
  }  
  
  .dropdown-label-wrap {
    width: 200px;
    margin-bottom: 24px;
    &__category{
      width: 400px;
      margin-bottom: 24px;
    }
  }    
 }
}