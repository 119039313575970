@import '../../assets/styles/style.scss';

.content-body {
  &.home {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 40px 63px;
    height: 100vh;

    .content-body {
      &__box {
        max-width: 980px;
        justify-content: space-between;
        gap: 40px;
      }
    }
  }
}
